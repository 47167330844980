.TaskIssueForehead {
    background: linear-gradient(131.57deg, #004D95 0%, #002E59 100%);
    color: #FFFFFF;
    padding: 88px 5%;
    position: relative;
    height: 970px;
}

.TaskIssueForehead h1 {
    position: relative;
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
    letter-spacing: -0.02em;
    max-width: 1000px;
    margin: 0 0 16px;
}

.TaskIssueForeheadBackground {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
}

.TaskIssueForehead .subheading {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    max-width: 400px;
}

.TaskIssueForeheadForm {
    margin-top: 50px;
}


.TaskIssueForeheadForm button {
    background: #FFBE28;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 16px;
    width: fit-content;
    color: #003E77;
    text-transform: none;
}


.TaskIssueForeheadIllustrationWrapper img {
    box-shadow: -15px 25px 50px -8px rgba(37, 38, 38, 0.4), 0 4px 20px -3px rgba(37, 38, 38, 0.1);
    border-radius: 4px;
}

.TaskIssueForeheadIllustrationFirstHuge {
    position: absolute;
    top: 290px;
    right: 5%;
    width: 499px;
    height: 289px;
    border-radius: 2%;
}

.TaskIssueForeheadIllustrationFirstSmall {
    position: absolute;
    top: 240px;
    right: 37%;
    width: 148px;
    height: 262px;
    border-radius: 2%;
}

.TaskIssueForeheadIllustrationSecondHuge {
    position: absolute;
    bottom: 85px;
    right: 25%;
    width: 509px;
    height: 287px;
    border-radius: 2%;
}

.TaskIssueForeheadIllustrationSecondSmall {
    position: absolute;
    bottom: 205px;
    right: 20%;
    z-index: 5;
    width: 148px;
    height: 260px;
    border-radius: 2%;
}

.TaskIssueWorkflow0 {
    background: linear-gradient(131.57deg, #3DD1DC 0%, #1A9AA4 100%);
}

.TaskIssueWorkflow1 {
    background: linear-gradient(131.57deg, #AA007B 0%, #66004A 100%);
}

.TaskIssueWorkflow2 {
    background: linear-gradient(131.57deg, #004D95 0%, #002E59 100%);
}

.TaskIssueWorkflow {
    box-shadow: 0 8px 24px -4px rgba(30, 44, 101, 0.3), 0 2px 6px -1px rgba(30, 44, 101, 0.15);
    border-radius: 4px;
    width: 32%;
    padding: 48px 48px 56px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TaskIssueWorkflow h2 {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    display: flex;
    align-items: center !important;
    text-align: center;

    margin: 16px 0 8px !important;
    color: white !important;
}

.TaskIssueWorkflow p {
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}


.TaskIssueListWorkflows {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.TaskIssueWorkflows {
    padding: 120px 5%;
}

.TaskIssueWorkflowsHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #252626;
    max-width: 850px;
    margin: 0 auto 64px;
}

.TaskIssueExampleTaskCreation {
    background: #F6F8F9;
}

.BiggerIlustration img, .BiggerIlustrationImage {
    width: 785px !important;
}


.TaskIssueFlexContainer {
    display: flex;
    justify-content: space-between;
    padding: 120px 5%;
}

.TaskIssueFlexContainer img {
    width: 738px;
    height: 472px;
    box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0 4px 20px -3px rgba(30, 44, 101, 0.05);
    border-radius: 4px;
}

.TaskIssueFlexContainer video {
    width: 738px;
    height: 491px;
    box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0 4px 20px -3px rgba(30, 44, 101, 0.05);
}

.TaskIssueFlexContainer h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    color: #252626;
    margin: 0;
}

.TaskIssueFlexContainer p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #5A667B;
    margin: 24px 0 0;
    padding: 0;
}

.TaskIssueFlexContainerInfo {
    margin: auto 0;
    width: 35%;
}

.TaskIssueFlexContainerInfo label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #252626;
}

p.TaskIssueUnderVideoLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: #5A667B;
    width: 100%;
}

.TaskIssueFlexContainerInfoVideoContainer {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.TaskIssueTaskCalendar {
    background: #5A667B;
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    height: 776px;
}

.TaskIssueTaskCalendarIllustrationSmallScreen {
    display: none;
}

.TaskIssueTaskCalendarInfo {
    width: 36%;
    padding: 144px 0 144px 4%;
    background: #5A667B;
    box-shadow: 30px 0 40px -20px rgba(0, 0, 0, 0.15);
}

.TaskIssueTaskCalendarInfo h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 39px;
    line-height: 49px;
    letter-spacing: -0.02em;
    margin-right: 5%;
}

.TaskIssueTaskCalendarInfo .subheading {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin: 16px 5% 64px 0;
}

.TaskIssueTaskCalendarInfo ul {
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.TaskIssueTaskCalendarInfo ul li {
    border-radius: 2px 0 0 2px;
    padding: 16px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TaskIssueTaskCalendarIllustration {
    margin: 0;
    width: 62%;
    padding: 105px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.TaskIssueTaskCalendarIllustration p {
    margin: 48px auto 20px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 12px;
    line-height: 21px;
    width: 67%;
}

.TaskIssueTaskCalendarIllustration img {
    width: 745px;
    height: 429px;
    border-radius: 4px;
}

.TaskIssueUniqueStructure {
    padding: 120px 5%;
    background: #252626;
    color: #FFFFFF;
    min-height: 868px;
}

.TaskIssueUniqueStructure h3, .TaskIssueUniqueStructure h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 39px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 64px;
}

.TaskIssueUniqueStructureData {
    display: flex;
    justify-content: space-between;
}

.TaskIssueUniqueStructureData img {
    box-shadow: -15px 25px 50px -8px rgba(37, 38, 38, 0.4), 0 4px 20px -3px rgba(37, 38, 38, 0.1);
    border-radius: 4px;
    width: 59%;
}

.TaskIssueListOfStructures {
    width: 32%;
    margin: auto 0;
}

.TaskIssueStructureChildren {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
}

.TaskIssueStructureChildren img {
    display: none;
}

.GetStartedTodayTitleBlock {
    width: 47%;
}
.GetStartedTodayTitleBlock h4{
    font-weight: 600;
    font-size: 39px;
    line-height: 49px;
    letter-spacing: -0.02em;
}

.GetStartedTodayTitleBlock h5 {
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 31px;
    padding: 0;
    margin: 24px 0 0;
}

.DigitalBlockScreen {
    width: 769px;
    height: 403px;
}

@media screen and (max-width: 1367px) {
    .BiggerIlustration img, .BiggerIlustrationImage {
        width: inherit !important;
    }

    .TaskIssueTaskCalendarIllustration img {
        width: 605px;
        height: 329px;
    }

    .TaskIssueForeheadIllustrationFirstHuge {
        width: 400px;
        height: 220px;
    }

    .TaskIssueForeheadIllustrationFirstSmall {
        width: 124px;
        height: 206px;
    }

    .TaskIssueForeheadIllustrationSecondHuge {
        width: 402px;
        height: 222px;
    }

    .TaskIssueForeheadIllustrationSecondSmall {
        width: 123px;
        height: 204px;
    }

    .TaskIssueFlexContainer video {
        width: 426px;
        height: 288px;
    }

    .TaskIssueFlexContainer img {
        width: 426px;
        height: 288px;
        box-shadow: 10px 20px 40px -8px rgba(30, 44, 101, 0.15), 0 4px 20px -3px rgba(30, 44, 101, 0.05);
        border-radius: 4px;
    }

    .TaskIssueUniqueStructureData img {
        box-shadow: -15px 25px 50px -8px rgba(37, 38, 38, 0.4), 0 4px 20px -3px rgba(37, 38, 38, 0.1);
        border-radius: 4px;
        width: 540px;
        height: 350px;
    }
}

@media (max-width: 1100px) {
    .TaskIssueForeheadBackground {
        display: none;
    }
}

@media screen and (max-width: 968px) {
    .GetStartedTodayTitleBlock h4{
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        text-align: left;
    }
    .TaskIssueForehead {
        padding: 56px 0 104px;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .BiggerIlustration img, .BiggerIlustrationImage {
        width: 326px!important;
    }

    .DigitalBlockScreen {
        width: 84%;
        max-width: 500px;
        margin: 0 auto;
        height: auto;
    }

    .TaskIssueForehead h1 {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.01em;
        max-width: 100%;
        margin: 0 8%;
    }

    .TaskIssueForeheadBackground {
        display: none;
    }

    .TaskIssueForehead .subheading {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        max-width: 100%;
        margin: 16px 8% 0;
    }

    .TaskIssueForeheadForm {
        margin: 40px 8% 0;
    }

    .GetStartedTodayTitleBlock {
        width: 100%;
        margin-bottom: 48px;
    }
    .GetStartedTodayTitleBlock h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0 0;
    }


    .TaskIssueForeheadIllustrationWrapper {
        position: relative;
        box-sizing: border-box;
        margin-top: 32px;
        overflow: hidden;
        width: 100%;
        height: 479px;
    }

    .TaskIssueForeheadIllustrationFirstHuge {
        top: 0;
        right: 120px;
        width: 315px;
        height: 183px;
    }

    .TaskIssueForeheadIllustrationFirstSmall {
        top: 46px;
        right: 370px;
        width: 94px;
        height: 166px;
    }

    .TaskIssueForeheadIllustrationSecondHuge {
        width: 322px;
        height: 182px;
        left: 218px;
        top: auto;
        bottom: 24px;
    }

    .TaskIssueForeheadIllustrationSecondSmall {
        width: 93px;
        height: 164px;
        left: 468px;
        bottom: 71px;
        z-index: 5;
    }



    .TaskIssueWorkflow {
        width: 100%;
        padding: 32px 24px;
        margin-top: 24px;

    }

    .TaskIssueWorkflow h2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        display: block;
    }

    .TaskIssueWorkflow p {
        font-style: normal;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 21px !important;

    }


    .TaskIssueListWorkflows {
        flex-direction: column;
    }

    .TaskIssueWorkflows {
        padding: 80px 9%;
    }

    .TaskIssueWorkflowsHeader {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        max-width: 100%;
        text-align: left;
        margin: 0 auto 26px;
    }

    .TaskIssueWorkflow h2 {
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 21px !important;
        display: block;
        text-align: center;
    }


    .TaskIssueFlexContainer {
        display: flex;
        flex-direction: column;
        justify-content: normal;
        padding: 80px 9%;
    }

    .TaskIssueFlexContainer h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
    }

    .TaskIssueFlexContainer p {
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
    }

    .TaskIssueFlexContainerInfo {
        margin: 0px auto 40px;
        width: 100%;
    }

    .TaskIssueFlexContainer video {
        width: 326px;
        height: 188px;
    }

    .TaskIssueFlexContainer img {
        width: 326px;
        height: 189px;
        box-shadow: 10px 20px 40px -8px rgba(30, 44, 101, 0.15), 0 4px 20px -3px rgba(30, 44, 101, 0.05);
        border-radius: 4px;
    }


    .TaskIssueFlexContainerWithRowReverse {
        flex-direction: column-reverse;
    }

    .TaskIssueUniqueStructure {
        padding: 80px 9%;
        height: auto;
    }

    .TaskIssueUniqueStructure h3, .TaskIssueUniqueStructure h2 {
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        text-align: left;
    }

    .TaskIssueUniqueStructureData {
        display: block;
        box-sizing: border-box;
    }

    .TaskIssueUniqueStructureData img {
        display: none;
    }

    .TaskIssueListOfStructures {
        width: 100%;
        margin: auto 0;
    }

    .TaskIssueStructureChildren {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
    }

    .TaskIssueStructureChildren img {
        display: block;
        margin-top: 24px;
        max-width: 500px;
        height: 300px;
        box-shadow: 10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0px 4px 20px -3px rgba(30, 44, 101, 0.05);
    }

    .TaskIssueFlexContainerInfoVideoContainer video {
        width: 50%;
    }

    .TaskIssueFlexContainerInfoVideoContainer {
        width: 100%;
    }
    .TaskIssueForeheadForm input{
        width:326px;
    }

    .TaskIssueTaskCalendarIllustration {
        display: none;
    }

    .TaskIssueTaskCalendar {
        justify-content: space-between;
        flex-direction: column;
        height: 900px;
        padding: 80px 0;
    }

    .TaskIssueTaskCalendarInfo {
        width: 100%;
        padding: 0;
        background: #5A667B;
        box-shadow: 30px 0 40px -20px rgba(0, 0, 0, 0.15);
    }

    .TaskIssueTaskCalendarInfo h2 {
        font-weight: 400;
        font-size: 25px;
        line-height: 31px;
        margin: 0 32px;
    }

    .TaskIssueTaskCalendarInfo .subheading {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin: 16px 32px 40px;
    }

    .TaskIssueTaskCalendarInfo ul li {
        padding: 16px 32px;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        border-top: 1px solid #7B8595;
        border-radius: 0;
    }

    .TaskIssueTaskCalendarInfo ul li:last-child {
        border-bottom: 1px solid #7B8595;
    }

    .TaskIssueTaskCalendarIllustrationSmallScreen {
        display: block;
        padding: 48px 32px;
    }

    .TaskIssueTaskCalendarIllustrationSmallScreen img {
        display: block;
        width: 326px;
        height: 186px;
        margin: 0 auto;
    }

    .TaskIssueTaskCalendarIllustrationSmallScreen p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        text-align: center;
    }
}
@media screen and (max-width: 650px) {
    .TaskIssueFlexContainerInfoVideoContainer video {
        width: 80%;
    }
}

@media screen and (max-width: 450px) {
    .TaskIssueFlexContainerInfoVideoContainer video {
        width: 100%;
    }

    .TaskIssueUniqueStructure {
        padding: 80px 8%;
    }

    .INFEvaluation img {
        height: auto;
    }
}

@media screen and (min-width: 1570px) {
    .TaskIssueForeheadIllustrationFirstSmall {
        right: 32%;
    }
}

@media (max-width: 968px) {
    p.TaskIssueUnderVideoLabel {
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
    }
}
@media (max-width: 768px) {
    .TaskIssueFlexContainerInfo label {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 8px;
        display: block;
    }

    .TaskIssueUnderVideoLabel {
        text-align: left;
    }

    .TaskIssueForeheadIllustrationFirstHuge {
        top: 0;
        right: -70px;
        width: 315px;
        height: 183px;
    }

    .TaskIssueForeheadIllustrationFirstSmall {
        top: 46px;
        right: 220px;
        width: 94px;
        height: 166px;
    }

    .TaskIssueForeheadIllustrationSecondHuge {
        width: 322px;
        height: 182px;
        left: -18px;
        top: auto;
        bottom: 24px;
    }

    .TaskIssueForeheadIllustrationSecondSmall {
        width: 93px;
        height: 164px;
        left: 268px;
        bottom: 71px;
        z-index: 5;
    }

}

@media screen and (max-width: 560px) {
    .TaskIssueStructureChildren img {
        width: 100%;
        height: 190px;
    }
}

@media screen and (max-width: 400px) {


    .TaskIssueForehead .subheading {
        margin: 0 6%;
    }

    .TaskIssueForeheadForm {
        margin: 40px 6% 0;
    }

    .TaskIssueForehead h1 {
        margin: 0 6% 16px;
    }

    .TaskIssueWorkflows {
        padding: 80px 8%;
    }

    .TaskIssueFlexContainer {
        padding: 80px 8%;
    }

}

@media screen and (min-width: 1800px) {
    .TaskIssueForeheadIllustrationFirstSmall {
        right: 28%;
    }
}

@media screen and (min-width: 1920px) {
    .TaskIssueForeheadIllustrationFirstSmall {
        right: 25%;
    }

    .TaskIssueForehead {
        padding: 88px 10%;
    }

    .TaskIssueWorkflows {
        padding: 120px 10%;
    }

    .TaskIssueFlexContainer {
        display: flex;
        justify-content: space-between;
        padding: 120px 10%;
    }

    .TaskIssueUniqueStructure {
        padding: 120px 10%;
    }

    .TaskIssueTaskCalendarInfo {
        width: 46%;
        padding: 144px 0 144px 9%;
        background: #5A667B;
        box-shadow: 30px 0 40px -20px rgba(0, 0, 0, 0.15);
    }
}
