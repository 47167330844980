.AboutUsForehead {
    width: 100%;
    height: 672px;
    position: relative;
    padding-top: 184px;
}

.AboutUsForeheadImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

.AboutUsForehead h1 {
    color: #FFFFFF;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    display: block;
    text-align: center;
    letter-spacing: -0.02em;
}

.AboutUsOurVisionBlock {
    width: 90%;
    background: #252626;
    box-shadow: 0 8px 30px -10px rgba(30, 44, 101, 0.12), 0 2px 6px -1px rgba(30, 44, 101, 0.05);
    border-radius: 8px;
    height: 406px;
    z-index: 3;
    position: absolute;
    bottom: -200px;
    left: 5%;
    box-sizing: border-box;
    padding: 70px 8% 80px;
}

.AboutUsOurVisionBlockColumns {
    display: flex;
    flex-direction: row;
    gap: 60px;
}

.AboutUsOurVisionBlockColumn {
    display: block;
    max-width: 509px;
}

.AboutUsOurVisionBlockColumn label {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFBE28;
}

.AboutUsOurVisionBlockColumn p {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 0;
    margin: 8px 0 0;
}

.AboutUsOurVisionBlock h2 {
    font-weight: 600;
    font-size: 39px;
    line-height: 49px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-bottom: 54px;
}

.AboutUsValues {
    padding: 246px 13% 120px;
}

.AboutUsValuesList {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}

.AboutUsValue {
    background: #FFFFFF;
    border-radius: 0 0 4px 4px;
    padding: 36px 36px 48px;
    box-shadow: 0px 8px 30px -10px rgba(30, 44, 101, 0.12), 0px 2px 6px -1px rgba(30, 44, 101, 0.05);
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-width: 326px;
    margin: 0 auto;
}

.AboutUsValue label {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    width: 98%;
    display: block;
    margin: 16px auto 0;
    text-align: center;
}

.ValueRectangle {
    border-radius: 4px 4px 0px 0px;
    height: 4px;
    width: 100%;
    position: absolute;
    top: -4px;
}

.AboutUsValues h2 {
    font-weight: 600;
    font-size: 39px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 64px;
}

.AboutUsOurVisionBlockSmallScreen {
    display: none;
}

.AboutUsOurTeam {
    background: #F6F8F9;
    padding: 120px 5%;
}

.AboutUsOurTeam h1 {
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 64px;
}

.AboutUsOurTeamEmployers {
    display: flex;
    justify-content: center;
    max-width: 1440px;
    margin: 0 auto;
}

.AboutUsOurTeamEmployer {
    margin: 0 16px 48px;
    min-width: 200px;
}

.AboutUsOurTeamEmployer.Group1 {
    min-width: 255px;
    height: 271px;
}

.AboutUsOurTeamEmployer:first-child {
    margin-left: 0;
}

.AboutUsOurTeamEmployer:last-child {
    margin-right: 0;
}

.AboutUsOurTeamEmployer p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    padding: 0;
    margin: 16px 0 0;
}

.AboutUsOurTeamEmployer label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #4B6FFC;
    display: block;
    max-width: 90%;
    margin: 0 auto;
}

.AboutUsOurTeamEmployer.Group1 .AboutUsOurTeamEmployerImageWrapper {
    max-width: 249px;
}

.AboutUsOurTeamEmployerImageWrapper {
    max-width: 220px;
    max-height: 220px;
    overflow: hidden;
    border-radius: 4px;
}

.AboutUsOurTeamEmployerImageWrapper img {
    width: 100%;
    min-width: 200px;
    height: 218px;
    border-radius: 4px;
    object-fit: cover;
    object-position: center 18%;
}

.AboutUsOurTeamEmployer.Group1.Center .AboutUsOurTeamEmployerImageWrapper img {
    object-position: center;
}

@media (max-width: 1600px) {
    .AboutUsOurTeamEmployer {
        margin: 0 16px 48px;
        min-width: 200px;
    }

    .AboutUsOurTeamEmployer.Group1 {
        min-width: 246px;
        height: 271px;
    }
}

@media (max-width: 1430px) {
    .AboutUsOurTeamEmployer {
        margin: 0 8px 48px;
        min-width: 189px;
    }

    .AboutUsOurTeamEmployer.Group1 {
        min-width: 230px;
        height: 271px;
    }
    .AboutUsOurTeamEmployerImageWrapper img {
        min-width: unset;
        object-fit: cover;
    }
}

@media (max-width: 1230px) {
    .AboutUsOurTeamEmployer {
        margin: 0 8px 48px;
        min-width: 180px;
    }

    .AboutUsOurTeamEmployer.Group1 {
        min-width: 220px;
        height: 271px;
    }

    .AboutUsOurTeamEmployerImageWrapper img {
        min-width: unset;
        object-fit: cover;
    }
}

@media (max-width: 1150px) {
    .AboutUsValues {
        padding: 246px 5% 120px;
    }
    .AboutUsOurTeamEmployer {
        margin: 0 8px 48px;
        min-width: 140px;
    }

    .AboutUsOurTeamEmployer.Group1 {
        min-width: 170px;
        height: 271px;
    }
}

@media (max-width: 968px) {
    .AboutUsValues h2 {
        font-weight: 400;
        font-size: 25px;
        line-height: 31px;
        margin-bottom: 52px;
    }
    .AboutUsOurVisionBlock {
        display: none;
    }
    .AboutUsValues {
        padding: 80px 8% 80px;
    }

    .AboutUsOurTeamEmployers {
        flex-wrap: wrap;
    }
    .AboutUsOurTeamEmployer {
        min-width: 255px;
    }

    .AboutUsOurTeamEmployer.Group1 {
        min-width: 255px;
        height: 271px;
    }

    .AboutUsOurTeamEmployerImageWrapper {
        max-width: 255px;
    }
    .AboutUsOurVisionBlockSmallScreen {
        display: block;
        background: #252626;
        box-shadow: 0 8px 30px -10px rgba(30, 44, 101, 0.12), 0 2px 6px -1px rgba(30, 44, 101, 0.05);
        border-radius: 8px;
        margin-top: -10px;
        position: relative;
        z-index: 3;
        padding: 80px 8%;
    }

    .AboutUsOurVisionBlockColumns {
        flex-direction: column;
        gap: 50px;
    }

    .AboutUsOurVisionBlockSmallScreen h2 {
        font-weight: 500;
        font-size: 31.25px;
        line-height: 39px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 48px;
    }
    .AboutUsOurTeam h1 {
        font-weight: 400;
        font-size: 25px;
        line-height: 31px;
        margin-bottom: 48px;
    }

}

@media (max-width: 600px) {
    .AboutUsForehead {
        height: 304px;
        padding-top: 100px;
    }

    .AboutUsForehead h1 {
        font-weight: 600;
        font-size: 31.25px;
        line-height: 39px;
        width: 90%;
    }
    .AboutUsOurTeamEmployer {
        margin: 0 16px 48px;
        min-width: 200px;
    }
    
    .AboutUsOurTeamEmployer:first-child,
    .AboutUsOurTeamEmployer:last-child {
        margin: 0 auto 24px;
    }

    .AboutUsOurTeamEmployer {
        width: 326px;
        overflow: hidden;
    }

    .AboutUsOurTeamEmployer img {
        width: 100%;
    }

    .AboutUsOurTeamEmployerImageWrapper {
        height: 216px;
        overflow: hidden;
        margin: 0 auto;
        max-width: unset;
    }

    .AboutUsOurTeamEmployer.Group1 .AboutUsOurTeamEmployerImageWrapper {
        max-width: unset;
    }
    .AboutUsOurTeamEmployerImageWrapper img {
        object-fit: cover;
    }
}


