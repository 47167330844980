.ImprintForehead {
    min-height: 700px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.ImprintForeheadData {
    width: 50.5%;
    padding: 64px 5% 100px;
    position: relative;
}

.ImprintForeheadData p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5A667B;
    margin: 0;
    padding: 0;
}

.ImprintForeheadData h1 {
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    margin-bottom: 80px;
}

.ImprintForeheadImage
{
   object-fit: cover;
   object-position: 60% 0;
}

.ImprintForeheadData h4 {
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    margin-bottom: 24px;
}

.paragraphBold {
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 24px;
    color: #5A667B;
    margin: 24px 0 !important;
}

.ImprintForeheadContactInfoBlock {
    background: #4B6FFC;
    box-shadow: 0 8px 24px -4px rgba(30, 44, 101, 0.3), 0 2px 6px -1px rgba(30, 44, 101, 0.15);
    border-radius: 4px;
    width: 930px;
    height: 104px;
    padding: 24px 36px;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
}

.ImprintForeheadContactInfoBlockWithIcon {
    padding: 8px 16px;
    gap: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
}

.ImprintForeheadContactInfoBlockWithIcon:hover {
    outline: 1px solid #FFFFFF;
    box-shadow: 0 0 4px rgba(255, 255, 255, 0.7);
    border-radius: 4px;
}

.ImprintForeheadContactInfoBlockWithIcon:last-child:hover {
    outline: none;
    box-shadow: none;
}
.ImprintForeheadContactInfoBlockWithIcon a{
    text-decoration: none;
    color: #FFFFFF;
}

.ImprintLine {
    height: 56px;
    border: 1px solid #6F8CFD;
}

#ImprintCountry {
    margin-top: 20px;
}

.label {
   font-weight: 600 !important;
   font-size: 16px;
   line-height: 24px;
   color: #5A667B;
   margin: 24px 0 0 0!important;
}

.value {
   font-size: 16px;
   line-height: 24px;
   color: #5A667B;
   margin: 0!important;
}

.section {
   margin: 36px 0 0 0!important;
}

@media (max-width: 1000px) {
    .ImprintForeheadContactInfoBlock {
        width: 326px;
        height: 256px;
        flex-direction: column;
    }

    .ImprintLine {
        width: 256px;
        height: 1px;
        border: 1px solid #6F8CFD;
    }
}

@media (max-width: 968px) {
    .ImprintForehead {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .ImprintForeheadImage {
        width: 100%;
        height: 100%;
    }

    .ImprintForeheadData {
        width: 100%;
        padding: 56px 0 170px;
        position: relative;
    }

    .ImprintForeheadData p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #5A667B;
        margin: 0;
        padding: 0;
    }

    .ImprintForeheadData h1 {
        font-weight: 600;
        font-size: 31.25px;
        line-height: 39px;
        text-align: center;
        margin-bottom: 36px;
    }

    .ImprintForeheadData h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
        text-align: center;
    }

    .paragraphBold {
        font-weight: 600 !important;
        font-size: 16px;
        line-height: 24px;
        color: #5A667B;
        margin: 24px 0 !important;
    }

    .ImprintForeheadContactInfoBlock {
        width: 326px;
        height: 256px;
        flex-direction: column;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        left: 0;
        right: 0;
        bottom: -127px;
    }

    .ImprintLine {
        width: 256px;
        height: 1px;
        border: 1px solid #6F8CFD;
    }

    #ImprintCountry {
        margin-top: 0;
    }

    .ImprintForeheadContactInfoBlockWithIcon:last-child {
        width: 90%;
        margin: 0 auto;
    }

}