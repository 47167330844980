.Forehead {
    background: linear-gradient(131.57deg, #004D95 0%, #002E59 100%);
    height: 970px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    color: #FFFFFF;
    padding: 64px 5% 0;
    display: flex;
    justify-content: space-between;
}

.IntroBackBlockSmallScreen {
    display: none;
}

.ForeheadLogo {
    position: absolute;
    top: 25px;
    left: 45%;
}

.ForeheadBack {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 60%;
    top: 0;
    height: 100%;
    background: linear-gradient(131.57deg, #004D95 0%, #002E59 100%);
    clip-path: polygon(18% 0%, 100% 0%, 100% 100%, 20% 100%, 6% 50%);
}

.ForeheadInformationTitle {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 31.25px !important;
    line-height: 39px !important;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin: 0 0 8px;
}

.ForeheadInformation {
    width: 40%;
    position: relative;
    z-index: 1;
}

.ForeheadInformation h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    letter-spacing: -0.02em;
    width: 110%;
}

.ForeheadInformation p, .ForeheadInformation .subheading {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: unset;
}

.ForeheadForm {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 48px;
    font-style: normal;
    width: fit-content;
}

.ForeheadHomepageForm input{
    width: 378px;
}

.ForeheadForm label {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 8px;
    font-family: 'Poppins',sans-serif;
}

.ForeheadForm input {
    flex-direction: row;
    align-items: flex-start;
    padding: 13.5px 16px;
    height: 48px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    border: none;
    outline: none;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    width: 100%;
    min-width: 308px;
    font-family: 'Poppins', sans-serif;
}

.ForeheadForm input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    font-family: 'Poppins',sans-serif;
}

.ForeheadForm button {
    width: fit-content;
    height: 48px;
    font-weight: 500!important;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFBE28;
    color: #003E77;
    text-transform: none;
    font-family: 'Poppins',sans-serif;
    border-radius: 4px;
    font-style: normal;
    letter-spacing: 0.02em;
}
.ForeheadForm button:hover{
    background: inherit;
    color: #FFBE28;
    outline: 1px solid #FFBE28;
}

.ForeheadForm p {
    padding: 0;
    margin: 0;
}

.ForeheadIllustration {
    width: 640px;
    position: relative;
    right: -50px;
    margin-top: 60px;
}

.ForeheadImage {
    position: absolute;
    border-radius: 5px;
}

.ForeheadMan {
    bottom: 0;
    right: -65px;
    z-index: 4;
    width: 812px;
    height: 859px;
}

.ForeheadMedium {
    top: -4%;
    right: 10%;
    width: 351px;
    height: 227px;
    box-shadow: 15px 25px 50px -8px rgba(37, 38, 38, 0.4), 0px 4px 20px -3px rgba(37, 38, 38, 0.1);
}

.ForeheadHuge {
    top: 6%;
    left: -10%;
    width: 473px;
    height: 291px;
    box-shadow: -15px 25px 50px -8px rgba(37, 38, 38, 0.4), 0px 4px 20px -3px rgba(37, 38, 38, 0.1);
}

.ForeheadSmall {
    top: 25%;
    left: 1%;
    z-index: 5;
    width: 123px;
    height: 300px;
    box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0px 4px 20px -3px rgba(30, 44, 101, 0.05);
}

.ForeheadMaskLaptop {
    z-index: 100;
    bottom: 185px;
    right: 430px;
    width: 280px;
    height: 226px;
}

.ForeheadManFirstText {
    width: 450px;
    height: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    position: absolute;
    right: 48px;
    bottom: 36%;
    z-index: 5;
    text-transform: uppercase;
    background: #20C1CD;
    border-radius: 20px;
    padding: 4px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    letter-spacing: 0.05em;
}

.ForeheadManSecondText {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #AA007B;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    width: 362px;
    height: 32px;
    padding: 4px 24px;
    position: absolute;
    right: 120px;
    bottom: 31%;
    z-index: 5;
    text-transform: uppercase;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.05em;
}

.Intro {
    padding: 120px 5%;
}

.IntroBlock {
    display: flex;
    justify-content: space-between;
    height: 440px;
}

.IntroImagesColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.IntroImagesColumn img {
    width: 200px;
    height: 128px;
}

.Intro h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    max-width: 962px;
    line-height: 61px;
    margin: 0 auto 8px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #252626;
}

.Intro p, .Intro .subheading {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    padding: 0;
    margin: 0 0 64px;
}

.ReadMore {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: 0.02em;
    display: flex;
    align-items: center;
    color: #4B6EF5;
    cursor: pointer;
}

.ReadMore img {
    margin: 2px 0 0 4px;
}

.PluginsContainer {
    padding: 120px 5% 80px;
    background: #F6F8F9;
}

.PluginsFlexList {
    width: 45%;
    margin: 64px auto;
    display: flex;
    justify-content: space-between;
}


.PluginsTitle {
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #252626;
    margin: 0 auto;
}

.Plugins h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    color: #252626;
    margin: 0 0 8px;
}
.Plugins .PluginsTitle{
    font-weight: 600;
    font-size: 39.063px;
    line-height: 49px;
}

.Plugins .subheading {
    font-style: normal;
    display: block;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto;
    padding: 0;
}

.SoftwarePlugins {
    margin: 64px 0;
    padding: 80px 6.25%;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
}

.SoftwarePluginsList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 52%;
}

.SoftwarePluginsText {
    width: 40%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #252626;
}

.Formats {
    padding: 0 18%;
}

.Formats div {
    display: flex;
    justify-content: space-between;
    margin: 40px 0;
    flex-wrap: wrap;
}

.Formats h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    margin: 0;
}

.Formats h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    margin: 0;
}

.CustomersContainer {
    padding: 120px 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CustomersContainer h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    display: block;
    text-align: center;
    letter-spacing: -0.02em;
    margin: 0 0 64px;
}

.CustomersList {
    position: relative;
    display: flex;
}

.CustomerCard {
    width: 426px;
    padding: 32px;
}

.CustomerCard img {
    width: 200px;
    height: 184px;
}

.CustomerCompanyName {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 0;
    margin: 8px 0 0;
    color: #4B6FFC;
}

.CustomerPosition {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #4B6FFC;
    padding: 0;
    margin: 0;
}

.CustomerCard h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #252626;
    margin: 24px 0 0;
}

.CustomerDescription {
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    color: #5A667B;
    padding: 0;
    margin: 8px 0 0;
    width: 100%;
}


.CustomersVector {
    position: absolute;
    top: 0;
    width: 100%;
}

.DifferentVisoplan {
    padding: 120px 13%;
}

.DifferentVisoplan div {
    display: flex;
    justify-content: space-between;
}

.DifferentVisoplan div h4 {
    width: 51%;
    font-style: normal;
    font-weight: 600;
    font-size: 39px;
    line-height: 49px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
}

.DifferentVisoplan div p {
    width: 39%;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #5A667B;
    margin: 0;
    padding: 0;
}

.DifferentVisoplanImages {
    padding: 0 5%;
    margin-top: 65px;
}

.DifferentVisoplanImages div {
    display: flex;
    flex-direction: column;
}

.DifferentVisoplanImages h6 {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 8px;
    text-align: center;
    padding: 0;
}

.DifferentVisoplanImages img {
    width: 400px;
    height: 400px;
}

.GetStartedToday {
    padding: 106px 10% 126px;
    background-image: url('../../assets/Homepage/webp/GetStartedBackground.png'),
                      url('../../assets/Homepage/webp/GetStartedBackground.webp');
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
}

.GetStartedTodayForm {
    width: 530px;
    box-sizing: border-box;
    height: 368px;
    padding: 32px;
    background: rgba(77, 205, 215, 0.4);
    backdrop-filter: blur(15px);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-family: 'Poppins', sans-serif;
}

.GetStartedTodayForm h4, .GetStartedTodayForm h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    margin: 0 0 32px;
}

.GetStartedTodayForm input {
    height: 48px;
    width: 100%;
    margin-bottom: 8px;
    padding: 11px 16px;
    box-sizing: border-box;
    outline: none;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
}

.GetStartedTodayForm input::placeholder {
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.GetStartedTodayForm button {
    margin-top: 24px;
    border-radius: 4px;
    width: fit-content;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    background: #FFBE28;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.02em;
    color: #003E77;
    height: 48px;
}
.GetStartedTodayForm button:hover{
    background: inherit;
    color: #FFBE28;
    outline: 1px solid #FFBE28;
}

.GetStartedTodayTitle {
    display: block;
    width: 44%;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 31.25px;
    line-height: 39px;
    letter-spacing: -0.01em;
}

.VisoplanAdvantageTriangle {
    background: #F6F8F9;
    padding: 120px 5%;
}

.VisoplanAdvantageTriangle h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
}

.VisoplanAdvantageTriangle .subheading {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    color: #000000;
    margin: 8px 0 64px;
}

.listOfSteps {
    display: flex;
    justify-content: space-between;
}

.listOfSteps li {
    width: 30%;
    list-style: none;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #5A667B;
}

.listOfSteps li::before {
    content: '2';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    background: #004D95;
    color: #FFFFFF;
    margin-left: -44px;
    margin-right: 20px;
}

.listOfStepsFirst::before {
    content: '1' !important;
}

.listOfSteps li:last-child::before {
    content: '3'
}

.TrianglesIllustration {
    display: block;
    box-sizing: border-box;
    width: 296px;
    position: relative;
}

.TrianglesIllustration img {
    width: 148px;
    height: 124px;
    transition: 2s;
    position: relative;
    z-index: 100;
}

.TriangleColumn {
    width: 34%;
}

.TriangleBlock {
    display: flex;
    justify-content: space-between;
}

.topAdvantage {
    display: block;
    margin: 0 auto;
}

.FewStepsImplements {
    margin-top: 80px;
}

.AdvantageTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 0;
    margin: 16px 0 4px;
    width: 110%;
}

.AdvantageDescription {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    padding: 0;
}

.TriangleColumn {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    height: 300px;
}

.animatedIcon {
    -webkit-transform: scale(1.3) translateY(10%);
    -moz-transform: scale(1.3) translateY(10%);
    -o-transform: scale(1.3) translateY(10%);
    transition: 1s;
}

.Puzzles {
    padding: 120px 5%;
}

.PuzzlesBody {
    border: 1px dashed #252626;
    border-radius: 16px;
    position: relative;
    min-height: 605px;
}

.PuzzleWrapper {
    position: relative;
    width: 155px;
    padding: 0;
    cursor: pointer;
}

.PuzzlesTitleWrapper {
    position: absolute;
    background:transparent;
    top: -50px;
    left: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PuzzlesTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 31.25px;
    line-height: 39px;
    align-items: center;
    color: #252626;
    display: flex;
    background: #FFFFFF;
    flex-direction: column;
    letter-spacing: -0.01em;
}

.PuzzlesTitle img {
    width: 347px;
    height: 48px;
    margin-right: 30px;
}

.PuzzlesList {
    margin: 100px auto 75px;
    display: flex;
    height: 292px;
    width: 1077px;
}

.PuzzleImage {
    position: relative;
    padding: 0;
    margin: 0;
    left: -24px;
}


.PuzzleImage1 {
    top: 70px;
    left: -55px;
}

.PuzzleImage2 {
    left: -84px;
}

.PuzzleImage3 {
    left: -70px;
    top: 70px;
}

.PuzzleImage4 {
    left: -70px;
}

.PuzzleImage5 {
    top: 70px;
    left: -81px;
}

.PuzzleImage6 {
    left: -45px;
}

.PuzzlesTitle h2 {
    margin-top: 16px;
    font-weight: 600;
}

.PuzzleActivText {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto 20px;
    width: 72%;
}

.LearnMore {
    margin: 20px auto 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.02em;
    color: #4B6FFC;
    cursor: pointer;
}

.LearnMore img {
    width: 16px;
    height: 16px;
    margin-left: 8px;
}

.FromStartToFinish {
    padding: 120px 5%;
    overflow: hidden;
    box-sizing: border-box;
    background: #252626;
    color: #FFFFFF;
}

.FromStartToFinish h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 48.828px;
    line-height: 61px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}

.FromStartToFinish .subheading {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
}

.FromStartToFinishCore {
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
}

.CoreColumn {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CoreColumnTitle {
    font-weight: 300;
    margin: 0 0 40px;
}

.CoreColumn p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}


.planning-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.planning-list li {
    list-style: none;
    width: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.construction-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.construction-list li {
    list-style: none;
    width: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}


.planning-list li:before {
    content: url('../../assets/Homepage/SquareListElement.svg');
    display: inline;
    vertical-align: middle;
    width: 50px;
    left: -10px;

}

.planning-list li span, .operation-list li span, .allWork-list li span, .construction-list li span {
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
}

.operation-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.allWork-list {
    list-style: none;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    padding: 0;
}

.operation-list li {
    list-style: none;
    width: 90%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.operation-list li:before {
    content: url('../../assets/Homepage/SquareListElement3.svg');
    display: inline;
    vertical-align: middle;
    left: -15px;
}

.construction-list li:before {
    content: url('../../assets/Homepage/SquareListElement4.svg');
    display: inline;
    vertical-align: middle;
    left: -15px;
}

.allWork-list li:before {
    content: url('../../assets/Homepage/SquareListElement2.svg');
    display: inline;
    vertical-align: middle;
    left: -15px;
}


.CoreCycle {
    width: 570px;
    height: 570px;
    position: relative;
}

.CoreCycle img {
    width: 560px;
}

.MainGraphic {
    position: relative;
    cursor: pointer;
}

.PlanningGraphic {
    position: absolute;
    top: 20%;
    right: 9%;
    z-index: 9;
    cursor: pointer;
}

.OperationGraphic {
    position: absolute;
    top: 23%;
    left: 5.2%;
    z-index: 9;
    cursor: pointer;
}

.ConstructionGraphic {
    position: absolute;
    bottom: 7.38%;
    right: 22.9%;
    z-index: 9;
    cursor: pointer;
}

.FromStartToFinishLogo {
    position: absolute;
    left: 35%;
    top: 45%;
    z-index: 10;
}

.listOfStepsNumbersSmallScreen {
    display: none;
}
.trianglesIllustrationSmallScreen {
    display: none;
}

.TriangleColumnBorderUpSecond {
    border-top: 1px solid #20C1CD;
    width: 142.5%;
    position: relative;
    left: -42.5%;
}

.TriangleColumnBorderUpFirst {
    border-top: 1px solid #004D95;
    width: 12%;
    position: relative;
    transition: 2s;
}

.TriangleColumnBorderUpThird {
    border-top: 1px solid #AA007B;
    width: 121.5%;
    position: relative;
    left: -21.5%;
}

@media (min-width: 1700px) {
    .ForeheadIllustration {
        width: 40%;
    }

}

@media (min-width: 2000px) {
    .ForeheadIllustration {
        width: 40%;
    }

    .Forehead {
        padding: 120px 8% 0;
    }
}
@media(max-width:1367px){
    .planning-list li,.construction-list li {
        width: 80%;
    }
    .operation-list li {
        width: 100%;
    }

}
@media (max-width:1300px){
    .ForeheadLogo {
        left: 42%;
    }
}

@media (max-width: 1200px) {
    .Forehead {
        background: linear-gradient(131.57deg, #004D95 0%, #002E59 100%);
        height: 1280px;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
        color: #FFFFFF;
        padding: 120px 0 0;
        flex-direction: column;
    }

    .ForeheadIllustration {
        width: 100%;
        position: relative;
        height: 535px;
    }

    .ForeheadBack {
        display: none;
    }

    .ForeheadLogo {
        position: absolute;
        top: 25px;
        left: 8%;
    }

    .ForeheadInformation {
        width: 100%;
        position: relative;
        padding: 0 9%;
        z-index: 1;
        margin-bottom: 50px;
    }

    .ForeheadInformationTitle {
        font-weight: 400;
        font-size: 25px;
        line-height: 31px;
    }

    .ForeheadInformation h1 {
        font-weight: 600;
        font-size: 31px;
        line-height: 39px;
    }

    .ForeheadForm {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 40px;
    }

    .ForeheadForm input {
        align-items: flex-start;
        padding: 13px 16px;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .ForeheadForm input::placeholder {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .ForeheadForm button {
        width: fit-content;
        height: 48px;
        margin-top: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .ForeheadMan {
        bottom: 0;
        right: 240px;
        z-index: 4;
        width: 585px;
        height: 613px;
    }

    .ForeheadHuge {
        top: 39px;
        left: 12%;

    }

    .ForeheadMedium {
        top: -100px;
        right: 30%;

    }

    .ForeheadSmall {
        left: 39%;
        top: 41px;
        z-index: 0;
    }

    .CustomersVector {
        display: none;
    }

    .ForeheadMaskLaptop {
        z-index: 100;
        bottom: 134px;
        width: 202px;
        height: 160px;
        right: 597px;
    }

    .ForeheadManFirstText {
        bottom: 39%;
        right: 215px;
    }

    .ForeheadManSecondText {
        padding: 0;
        position: absolute;
        right: 275px;
        bottom: 31%;
        z-index: 5;
        text-transform: uppercase;
    }

    .FromStartToFinish {
        padding: 120px 8% 60px 8%;
    }


    .Puzzles {
        padding: 80px 9%;
    }

    .PuzzlesBody {
        border: 1px dashed #252626;
        border-radius: 16px;
        position: relative;
    }

    .PuzzleWrapper {
        position: relative;
        width: 320px;
        height: 1100px;
        padding: 0;
        cursor: pointer;
    }

    .PuzzlesTitleWrapper {
        top: -35px;
    }

    .PuzzlesTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        letter-spacing: -0.01em;
        color: #252626;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
    }

    .PuzzlesTitle h2 {
        font-weight: 600;
        line-height: 24px;
        font-size: 16px !important;
    }

    .PuzzlesTitle img {
        width: 173.45px;
        height: 24px;
    }

    .PuzzlesList {
        display: flex;
        flex-direction: column;
        width: 320px;
        margin: 60px auto 0;
        height: 1200px;
    }

    .PuzzleImage {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
    }

    .PuzzleImage0 {
        top: 10px;
    }


    .PuzzleImage1 {
        top: -20px;
        left: auto;
    }

    .PuzzleImage2 {
        left: auto;
        top: -43px;
    }

    .PuzzleImage3 {
        top: -65px;
        left: auto;
    }

    .PuzzleImage4 {
        top: -87px;
        left: auto;
    }

    .PuzzleImage5 {
        top: -110px;
        left: auto;
    }

    .PuzzleImage6 {
        top: -132px;
        left: auto;
    }

    .PuzzleActivText {
        display: none;
    }

    .PuzzleImage h3 {
        padding: 5px 16px;
        background: #4B6FFC;
        box-shadow: 0 8px 30px -10px rgba(30, 44, 101, 0.12), 0 2px 6px -1px rgba(30, 44, 101, 0.05);
        border-radius: 20px;
        color: #FFFFFF;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        text-transform: uppercase;
    }

    .PuzzleImage p {
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        text-align: center;
        margin-bottom: 30px;
    }

    .TriangleColumnBorderUpSecond {
        display: none;
    }

    .TriangleColumnBorderUpFirst {
        display: none;
    }

    .TriangleColumnBorderUpThird {
        display: none;
    }
    .CustomersList {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
    }

    .CustomersList > .CustomersVector {
        height: 1650px;
    }

    .CustomersContainer h5 {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        margin: 0 0 40px;
    }

    .CustomersContainer {
        padding: 80px 9%;
    }

}

@media (max-width: 1100px) {
    .TrianglesIllustration {
        width: 266px;
    }
    .TrianglesIllustration img {
        width: 128px;
        height: 95px;
        transition: 2s;
        position: relative;
        z-index: 100;
    }
}

@media (max-width: 968px) {
    .TriangleColumnBorderUpThird, .TriangleColumnBorderUpFirst, .TriangleColumnBorderUpSecond {
        display: none;
    }
    .CoreColumnTitle {
        font-weight: 400;
        font-size: 14px!important;
        line-height: 21px;
    }

    .topAdvantage {
        display: block;
        margin: 0;
        padding-left: 70px;
    }

    .GetStartedToday {
        padding: 80px 9%;
        display: flex;
        flex-direction: column;
        justify-content: normal;
        color: #FFFFFF;
    }

    .GetStartedTodayForm {
        width: 100%;
        box-sizing: border-box;
        height: 318px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .GetStartedTodayForm h4, .GetStartedTodayForm h3 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 16px;
        width: auto;
    }

    .GetStartedTodayForm input {
        background: rgba(255, 255, 255, 0.2);
        color: #FFFFFF;
        width: 100%;
        padding: 11px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }

    .GetStartedTodayForm input::placeholder {
        color: #FFFFFF;
    }

    .GetStartedTodayForm button {
        margin-top: 16px;
        background: #FFBE28;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }

    .GetStartedTodayTitle {
        display: block;
        width: 100%;
        margin: 0 0 48px;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        letter-spacing: -0.01em;
    }

    .GetStartedToday p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 0;
        text-align: left;
        margin: 16px auto 48px 0;
    }

    .Forehead {
        background: linear-gradient(131.57deg, #004D95 0%, #002E59 100%);
        height: 1280px;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
        color: #FFFFFF;
        padding: 120px 0 0;
        flex-direction: column;
    }

    .ForeheadIllustration {
        width: 100%;
        position: relative;
        height: 535px;
    }

    .ForeheadBack {
        display: none;
    }

    .ForeheadLogo {
        width: 160px;
        height: 50px;
        position: absolute;
        top: 25px;
        left: 30%;
    }

    .ForeheadInformation {
        width: 100%;
        position: relative;
        padding: 0 9%;
        z-index: 1;
        margin-bottom: 50px;
    }

    .ForeheadInformationTitle {
        font-weight: 400;
        font-size: 25px;
        line-height: 31px;
    }

    .ForeheadInformation h1 {
        font-weight: 600;
        font-size: 31px;
        line-height: 39px;
    }

    .ForeheadForm {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 40px;
        width: auto;
    }

    .ForeheadForm input {
        align-items: flex-start;
        padding: 13px 16px;
        width: 100% !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .ForeheadForm input::placeholder {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .ForeheadForm button {
        width: fit-content;
        height: 48px;
        margin-top: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .ForeheadMan {
        bottom: 0;
        right: -50px;
        z-index: 4;
        width: 485px;
        height: 513px;
    }

    .ForeheadHuge {
        top: 20px;
        left: 0%;
        width: 242px;
        height: 154px;
    }

    .ForeheadMedium {
        top: -10px;
        right: 20%;
        width: 158px;
        height: 103px;
    }

    .ForeheadSmall {
        width: 72px;
        height: 176px;
        left: 15%;
        top: 100px;
    }

    .ForeheadMaskLaptop {
        z-index: 100;
        bottom: 104px;
        width: 173px;
        height: 140px;
        right: 244px;
    }

    .ForeheadManFirstText {
        width: 228px;
        height: 20px;
        right: 60px;
        bottom: 180px;
        z-index: 5;
        font-size: 8px;
        line-height: 12px;
        text-transform: uppercase;
        padding: 0;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        justify-content: center;
    }

    .ForeheadManSecondText {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 8px;
        line-height: 12px;
        width: 200px;
        height: 20px;
        padding: 0;
        position: absolute;
        right: 80px;
        bottom: 150px;
        z-index: 5;
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }

    .IntroBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 112px;
        box-sizing: border-box;
    }

    .IntroImagesColumn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .IntroImagesColumn img {
        width: 100px;
        height: 40px;
    }

    .Intro {
        padding: 80px 9%;
    }

    .IntroBackBlock {
        display: none;
    }

    .IntroBackBlockSmallScreen {
        display: block;
        max-height: 189px;
        background: #5A667B;
        box-shadow: 10px 20px 40px -8px rgba(30, 44, 101, 0.15), 0 4px 20px -3px rgba(30, 44, 101, 0.05);
        border-radius: 4px;
        margin: 40px auto;
    }

    .Intro h2 {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        width: 100%;
        margin: 0 0 8px;
        display: block;
        text-align: center;
    }

    .Intro p, .Intro .subheading {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }

    .DifferentVisoplan {
        padding: 80px 8%;
    }

    .DifferentVisoplan div {
        display: flex;
        flex-direction: column;
    }

    .DifferentVisoplan div h4 {
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 24px;
    }

    .DifferentVisoplan div p {
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
    }

    .DifferentVisoplanImages {
        padding: 0;
        margin: 0;
    }

    .DifferentVisoplanImages img {
        width: 326px;
        height: 326px;
        margin: 0 auto;
    }

    .DifferentVisoplanImages div {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
    }

    .DifferentVisoplanImages h6 {
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 5px;
    }

    .PluginsFlexList {
        width: 90%;
        margin: 40px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .PluginsContainer {
        padding: 80px 8%;
    }

    .PluginsTitle {
        font-weight: 400 !important;
        font-size: 22px !important;
        line-height: 28px !important;
    }

    .Plugins h1 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .Plugins .subheading {
        font-style: normal;
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin: 8px auto 0;
    }

    .SoftwarePlugins {
        margin: 64px 0;
        padding: 80px 6.25%;
        background: #FFFFFF;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .SoftwarePluginsText {
        width: 100%;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #252626;
        margin-bottom: 40px;
    }

    .Formats {
        padding: 0;
    }




    .listOfSteps {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
    }

    .listOfSteps li {
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        margin-bottom: 24px;
    }

    .listOfSteps li::before {
        display: none;
    }

    .listOfStepsNumbersSmallScreen {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        background: #004D95;
        border-radius: 24px;
        color: #FFFFFF;
        margin: 0 auto 16px;
    }

    .FewStepsImplements h3 {
        margin: 0 0 32px;
    }

    .trianglesIllustrationSmallScreen {
        display: block;
    }

    .TrianglesIllustration {
        display: none;
    }

    .VisoplanAdvantageTriangle h3 {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
    }

    .VisoplanAdvantageTriangle .subheading {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 8px 0 40px;
    }

    .TriangleBlock {
        display: flex;
        flex-direction: column;
    }

    .TriangleColumn {
        width: 100%;
        justify-content: normal;
        gap: 32px;
        height: auto;
    }

    .AdvantageDescription {
        width: 95%;
        margin-left: auto;
    }

    .AdvantageTitle {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        width: 100%;
        padding: 0;
        display: flex;
        align-items: center;
    }

    .AdvantageTitle img {
        margin-right: 8px;
    }

    .trianglesIllustrationSmallScreen {
        display: block;
        box-sizing: border-box;
        width: 78%;
        margin: 48px auto 0;
    }

    .trianglesIllustrationSmallScreen img {
        width: 140px;
        height: 114px;
    }

    .FromStartToFinish {
        padding-top:60px;
        padding-bottom: 60px;
        height: auto;
    }
    .CoreCycle img {
        width: 320px;
    }

    .FromStartToFinishCore {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .Plugins h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .CoreColumn {
        width: 100%;
        padding: 0 0 80px;
    }

    .CoreColumn p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }

    .FromStartToFinish h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        text-align: left;
    }

    .FromStartToFinish .subheading {
        margin-top: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
    }

    .CoreColumn ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
    }

    .CoreColumn ul li {
        list-style: none;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        display: flex;
    }

    .CoreColumn ul li:before {
        width: auto;
    }

    .planning-list li span, .operation-list li span, .allWork-list li span, .construction-list li span {
        margin-left: 10px;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        display: block;
    }

    .CoreCycle {
        position: relative;
        width: 320px;
        height: 320px;
        margin: 30px auto;
    }

    .Formats h5 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .SoftwarePluginsList {
        width: 90%;
        margin: 0 auto;
    }

    .ForeheadHomepageForm input {
        width: 326px;
    }

    .LearnMore {
        margin: 12px auto 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
    }

    .LearnMore img {
        width: 12px;
        height: 12px;
    }

    .Formats h4 {
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
    }


}

@media (max-width: 450px) {
    .trianglesIllustrationSmallScreen {
        width: 82%;
    }
    .CustomersContainer {
        padding: 120px 0;
    }

    .CustomerCard {
        width: 100%;
        padding: 32px;
    }

    .CustomerCard img {
        width: 120px;
        height: 120px;
    }

    .CustomerCard p {
        font-size: 14px;
        line-height: 21px;
    }

}

@media (max-width: 400px) {
    .Formats img {
        width: 93px;
        height: 104px;
    }
    .CustomerDescription {
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        width: 100%;
    }

    .CustomerDescription {
        width: 90%;
    }

    .trianglesIllustrationSmallScreen {
        width: 90%;
        height: auto;
    }


    .AdvantageDescription {
        width: 90%;
        margin-left: auto;
    }
    .FromStartToFinish {
        padding: 80px 8% 0 8%;
    }
}

@media (max-width: 380px) {
    .PuzzlesList {
        display: flex;
        flex-direction: column;
        width: 300px;
        margin: 60px auto 0;
        height: 1200px;
    }
    .PuzzleWrapper {
        width: 300px;
        cursor: pointer;
    }

    .CustomerCard img {
        width: 180px;
        height: 164px;
    }

    .ForeheadInformation {
        padding: 0 6%;
    }

    .Intro {
        padding: 80px 5%;
    }

    .trianglesIllustrationSmallScreen {
        width: 90%;
        height: auto;
    }
    .IntroBackBlockSmallScreen {
        max-height: 169px;
    }

    .CustomerCard {
        width: 100%;
        padding: 32px;
    }
}


@media (min-width: 1920px) {
    .Intro, FromStartToFinish {
        padding: 120px 10%;
    }

    .PluginsContainer {
        padding: 120px 10% 80px;
    }

    .ForeheadBack {
        width: 60%;
    }

    .ForeheadLogo {
        left: 45%;
    }

    .FromStartToFinishCore {
        padding: 0px 5%;
    }

    .VisoplanAdvantageTriangle {
        background: #F6F8F9;
        padding: 120px 10%;
    }
    .Puzzles {
        padding: 120px 9%;
    }
    .GetStartedToday {
        padding: 106px 10% 126px;
    }
    .PluginsContainer {
        padding: 120px 12% 80px;
        background: #F6F8F9;
    }
}

@media (min-width: 2500px) {
    .ForeheadBack {
        width: 62%;
    }
}