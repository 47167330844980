.INFForehead {
    padding: 64px 5%;
}
.INFEvaluation img {
    width: 480px;
    height: 401px;
    object-fit: contain;
}

.INFForeheadIllustration label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #252626;
    position: relative;
    z-index: 2;
    width: 58%;
    margin: 20px 5% 31px 0;
}

.INFForeheadIllustration {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: start;
}

.INFForeheadIllustrationGraphic {
    width: 608px;
    height: 608px;
}


.INFForeheadIllustrationBackground {
    top: -55px;
}

.INFForeheadData p {
    font-style: normal;
    font-weight: 300!important;
    font-size: 16px!important;
    line-height: 24px!important;
    margin: 0 0 24px!important;
    padding: 0;
    width: 98%!important;
}

.INFForeheadData h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 39.063px;
    line-height: 49px;
    letter-spacing: -0.02em;
    color: #252626;
}

.INFForeheadData {
    width: 40%;
}

.INFForeheadForm {
    margin-top: 80px;
}

.INFForeheadForm label {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
}

.INFForeheadForm input {
    min-width: 308px;
}

.bigLabel {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 22px !important;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #252626 !important;
    margin: 0 0 8px !important;
}

.underTitleBigLabel {
    margin: 8px 0 0!important;
}

.INFEvaluation {
    height: 661px;
    padding: 120px 10% 120px 5%;
    background: #252626;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
}

.INFEvaluationData {
    width: 43%;
}

.INFEvaluationData h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    margin-bottom: 24px;
}

.INFEvaluationData label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 24px 0;
    display: block;
}

.INFEvaluationData p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    padding: 0;
    width: 99%;
}

.IFCFlexContainerIllustrationWithDescription {
    width: 738px;
}

.IFCFlexContainerIllustrationWithDescription p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: #5A667B;
    margin: 64px 0 0;
    padding: 0;
    width: 100%;
}

.INFStructureImgSmallScreen {
    display: none;
}

@media (min-width: 1700px) {
    .INFForeheadIllustrationGraphic {
        margin-right: 5%;
    }
}
@media (min-width: 1900px) {
    .INFForeheadIllustrationGraphic {
        margin-right: 5%;
    }
}


@media (max-width: 1500px) {
    .INFForeheadIllustration label {
        margin: 0 10% 41px 8%;
    }
}


@media (max-width: 1400px) {
    .INFForeheadIllustrationBackground {
        top: -55px;
        right: -100px;
    }

    .INFForeheadIllustration label {
        margin: 0 8% 41px 8%;
    }
}

@media (max-width: 1200px) {
    .IFCFlexContainerIllustrationWithDescription {
        width: 628px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .INFForeheadIllustrationGraphic {
        width: 508px;
        height: 508px;
    }

    .INFForeheadIllustrationBackground {
        right: -200px;
    }

    .INFForehead {
        padding: 34px 2%;
        box-sizing: border-box;
    }

    .INFEvaluation {
        height: auto;
        padding: 120px 8% 120px 5%;
    }
}
@media (max-width: 396px) {
    .IFCForeheadIllustrationGraphicSmallScreen.INFForeheadIllustrationGraphicSmallScreen {
        margin-bottom: 40px;
    }
}

@media (max-width: 968px) {
    .INFForeheadForm input {
        width:auto;
    }
    .IFCFlexContainerIllustrationWithDescription {
        width: 95%;
        margin: 0 auto;
    }

    .IFCFlexContainerIllustrationWithDescription p {
        text-align: left;
        margin: 0;
    }

    .INFForehead {
        flex-direction: column;
        height: 1540px;
        padding: 0;
    }

    .INFForeheadData p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        width: 98%;
        margin: 0 0 16px;
    }

    .INFForeheadData h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        letter-spacing: -0.01em;
    }

    .INFForeheadData {
        width: 100%;
        padding: 56px 8% 0;
    }

    .INFForeheadForm {
        margin-top: 40px;
    }

    .INFForehead input {
        width: 100%;
    }

    .INFForeheadIllustrationGraphicSmallScreen {
        width: 326px;
        height: 325px;
        margin-bottom: 92px;
        margin-top: 0;
        display: block;
    }

    .INFForeheadIllustration {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .INFForeheadIllustration label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #252626;
        position: relative;
        width: auto;
        z-index: 2;
        display: block;
        margin: 110px 8% 41px 8%;
    }

    .INFForeheadIllustrationBackground {
        top: 0;
        right: 0;
    }

    .INFStructureImgSmallScreen {
        display:block;
        width: 326px!important;
        height: 200px!important;
        box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
    }
    .INFEvaluation {
        height: 967px;
        padding: 80px 8%;
        flex-direction: column;
    }

    .INFEvaluationData {
        width: 100%;
    }

    .INFEvaluationData h2 {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 24px;
    }

    .INFEvaluationData label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin: 16px 0;
        display: block;
    }
    .INFContainerSliderPhoto img{
        width: 326px!important;
        height: 184px!important;
        box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0px 4px 20px -3px rgba(30, 44, 101, 0.05);
        border-radius: 4px;
    }

    .INFEvaluationData p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin: 0;
        padding: 0;
        width: 99%;
    }

    .INFEvaluation img {
        width: 270px;
        height: 230px;
        margin: 0 auto;
    }
}

@media (max-width: 380px) {
    .INFForeheadData h1 {
        font-size: 28px;
    }

    .INFForeheadData {
        padding: 56px 7%;
    }
}

@media (min-width: 1920px) {
    .INFForehead {
        padding: 64px 5% 64px 10%;
    }

    .INFEvaluation {
        padding: 120px 10% 120px 10%;
    }
}