.Container {
    padding: 80px 5%;
    background: #252626;
    box-sizing: border-box;
    color: #FFFFFF;
}

.Container ul {
    list-style: none;
    padding: 0;
    width: 26%;
}

.LogonWrapper  {
    padding: 0;
    width: 100%;
    margin-bottom: 32px;
}

.Container ul li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 11px;
}

.Container ul li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 11px;
}

.Container ul label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 10px;
    display: block;
    margin-bottom: 12px;
}

.FooterBody {
    display: flex;
    justify-content: space-between;
}

.FooterColumns {
    width: 65%;
    display: flex;
    justify-content: space-between;
}

.FooterColumns ul {
    margin-top: 0;
    margin-right: 50px;
    margin-bottom: 0;
}

.FooterColumns ul li a {
    cursor: pointer;
    color: #FFFFFF;
    text-decoration: none;
}

.FooterColumns ul li:last-child {
    margin-bottom: 0;
}

.FooterColumns ul li.route:hover {
    text-decoration: underline;
}

.FooterContactsColumn {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.FooterContactsColumn p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    white-space: pre-wrap;
    margin-top: 0;
}

.FooterContactsForm {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 4px 4px 4px 8px;
    height: 48px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
}

.FooterContactsForm input {
    display: block;
    margin: 0;
    background-color: transparent;
    text-shadow: none;
    outline: none;
    border: none;
    color: rgba(255, 255, 255, 0.55);
    padding: 3px 0;
    width: 100%;
}

.FooterContactsForm button {
    width: 107px;
    display: flex;
    padding: 5px 7px;
    align-items: center;
    justify-content: center;
}

.FooterContactsForm button:hover {
    color: #4B6FFC;
    border: 1px solid #4B6FFC;
    background: #FFFFFF;
}

.SocialMediaList {
    display: flex;
    width: 82%;
    margin-left: auto;
    justify-content: space-between;
    align-items: center;
}

.SocialMediaWrapper {
    width: 100%;
    margin-top: auto;
}

.SocialMediaWrapper p {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.05em;
}

.FooterContactsColumn ul {
    display: none;
}

@media (max-width: 768px) {
    .FooterColumns {
        display: none;
    }

    .FooterContactsColumn {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .FooterContactsColumn p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        white-space: pre-wrap;
    }

    .SocialMediaWrapper p {
        text-align: left;
    }

    .SocialMediaList {
        margin-left: 0;
        width: 75%;
    }

    .FooterContactsColumn ul {
        width: 100%;
        display: block;
    }
}
@media (min-width: 1920px){
    .Container {
        padding: 80px 12%;
    }
}