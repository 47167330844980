.BIMIntuitiveModel {
    padding: 120px 5%;
}

.BIMIntuitiveModel h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 39px;
    line-height: 49px;
    width: 65%;
    text-align: center;
    margin: 0 auto 64px;
}

.BimAnimatedComponent {
    width: 100%;
    overflow: hidden;
    height: 1040px;
    background: #F6F8F9;
    padding: 120px 0;
}

.BimCarousel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
    transition: translate;
    transition-property: transform;
    transition-duration: 1000ms;
    transition-timing-function: ease-in-out;
}

.BIMPhotoCarousel{
    margin: 0 50px;
}
.BIMPhotoCarousel img {
    width: 632px;
    height: 357px;
    box-shadow: 10px 20px 40px -8px rgba(30, 44, 101, 0.15), 0 4px 20px -3px rgba(30, 44, 101, 0.05);
    border-radius: 4px;
    transition: width 2s, height 2s;
    cursor: pointer;
}

.BIMPhotoCarouselActive img {
    width: 848px;
    height: 481px;
    box-shadow: 10px 20px 40px -8px rgba(30, 44, 101, 0.15), 0 4px 20px -3px rgba(30, 44, 101, 0.05);
    border-radius: 4px;
    transition: width 2s, height 2s;
    cursor: pointer;
}

.BimAnimatedComponentData {
    padding: 0 5%;
}

.BimAnimatedComponentData h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 39px;
    line-height: 49px;
    letter-spacing: -0.02em;
    text-align: center;
}

.BimAnimatedComponentData div {
    width: fit-content;
    margin: 32px auto;
    gap: 18px;
    display: flex;
    justify-content: space-between;
}

.BimAnimatedComponentData button {
    padding: 13.5px 16px;
    border-radius: 4px;
    width: auto;
    height: 48px;
    background: #F6F8F9;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #5A667B;
    border: 1px solid #5A667B;
    display: flex;
    align-items: center;
}

.BimAnimatedComponentData p {
    width: 64%;
    margin: 0 auto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #5A667B;
    text-align: center;
}

.BIMAnimatedContentButtonActive {
    box-shadow: 2px 2px 6px rgba(189, 194, 202, 0.2), 10px 10px 10px rgba(222, 224, 229, 0.3);
    border: none !important;
    background: #4B6FFC !important;
    color: #FFFFFF !important;
}

.BicForehead {
    position: relative;
    height: 1024px;
    box-sizing: border-box;
    display: flex;
    padding: 0;
    color: #FFFFFF;
    background: linear-gradient(131.57deg, #004D95 0%, #002E59 100%);
}
.BimForeheadForm input{
    min-width:320px;
}
.BimForeheadForm label{
    font-style: normal;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing:0 !important;
}

.BicForeheadInformation {
    width: 40%;
    padding: 64px 2% 0 5%;
}

.BicForeheadInformation label, .BicForeheadInformation .subheading {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.05em;
    width: 100%;
}

.BicForeheadInformation h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin: 16px 0 80px;
    width: 100%;
}

.BimForeheadWithCarousel {
    width: 60%;
    height: 1024px;
    box-sizing: border-box;
    /*background: linear-gradient(131.57deg, #004D95 0%, #002E59 100%);*/
    mix-blend-mode: normal;
}

.BicForeheadForm button {
    width: fit-content;
    color: #003E77;
}

.BimListIntuitiveModel {
    box-shadow: 10px 20px 40px -8px rgba(30, 44, 101, 0.15), 0 4px 20px -3px rgba(30, 44, 101, 0.05);
}
@media (max-width:1100px){
    .BicForeheadInformation {
        padding: 74px 2% 0 2%;
    }
}
@media (max-width:968px){
    .BicForeheadInformation label, .BicForeheadInformation .subheading {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .BicForeheadInformation h1 {
        font-weight: 600;
        font-size: 31px;
        line-height: 39px;
    }
    .BIMIntuitiveModel h2 {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        width: 100%;
        text-align: left;
        margin: 0 auto 40px;
    }

    .BIMIntuitiveModel {
        padding: 80px 6%;
    }

    .BimAnimatedComponentData h2 {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        text-align: left;
    }

    .BimAnimatedComponentData div {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: space-between;
    }

    .BimAnimatedComponentData p {
        width: 98%;
        margin: 0 auto;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: left;
        color: #5A667B;
    }


    .BIMPhotoCarouselActive img {
        width: 326px;
        height: 183.72px;
        box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0 4px 20px -3px rgba(30, 44, 101, 0.05);
        border-radius: 4px;
        transition: width 2s, height 2s;
        cursor: pointer;
    }

    .BIMPhotoCarousel img {
        width: 326px;
        height: 183.72px;
        box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0 4px 20px -3px rgba(30, 44, 101, 0.05);
        border-radius: 4px;
        transition: width 2s, height 2s;
        cursor: pointer;
    }

    .BimAnimatedComponent {
        height: 871px;
        padding: 80px 0;
    }

    .BimAnimatedComponentData button {
        width: fit-content;
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
    }

    .BimForeheadForm input {
        width: 326px;
    }
}

@media (max-width: 768px) {
    .BicForehead {
        display: block;
        background: linear-gradient(131.57deg, #004D95 0%, #002E59 100%);
    }

    .BimButtonFull {
        width: 100% !important;
    }

    .BimForeheadWithCarousel {
        padding-top: 20px;
        height: 270px;
        width: 100%;
    }

    .BicForeheadInformation {
        padding: 56px 32px 24px;
        width: 100%;
    }

    .BimAnimatedComponentDataDEButtons button {
        width: 295px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin: 0 auto;
    }

}

@media (max-width: 420px) {
    .BimAnimatedComponentData button {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 0px;
    }
}

@media (max-width: 380px) {
    .BicForeheadInformation {
        padding: 56px 20px 24px;
        width: 100%;
    }
}

@media (min-width: 1920px) {
    .BIMIntuitiveModel {
        padding: 120px 10%;
    }

    .BimAnimatedComponentData {
        padding: 0 10%;
    }
}