.WorkflowForehead {
  position: relative;
  height: calc(100vh - 57px);
  box-sizing: border-box;
  display: flex;
  padding: 0;
  justify-content: space-between;
  color: #ffffff;
  background: linear-gradient(131.57deg, #004d95 0%, #002e59 100%);
  padding: 88px 5%;
}

.Workflow {
  padding: 80px 5% 60px 5%;
}

.WorkflowForeheadInformation {
  width: 39%;
}

.WorkflowCardsList {
  display: flex;
}

.WorkflowCard {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #5a667b;
  min-height: 223px;
  border-radius: 16px;
  margin-right: 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #5a667b;
}

.WorkflowCard:nth-child(4) {
  margin-right: 0px;
}

.ActiveCard {
  transition: 0.3s ease-in-out;
  background: #4b6ffc;
  color: #ffffff;
  border: none;
}

.ActiveCard .CardReadMore {
  transition: 0.3s ease-in-out;
  background: #3c59ca;
  border-top: 0;
  color: #ffffff;
  fill: #ffffff;
  stroke: #ffffff;
}


.CardDescription {
  position: relative;
  min-height: 175px;
  cursor: pointer;
  padding: 32px 16px 5px;
}

.CardDescriptionImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #ffffff;
}

.CardDescription p {
  margin-top: 16px;
}

.ImgPosition {
  position: absolute;
  left: 60px;
}

.CardReadMore {
  height: 50px;
  width: 100%;
  display: flex;
  background-color: transparent;
  color: #5a667b;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-top: 1px solid #5a667b;
  border-radius: 0px 0px 16px 16px;
  stroke: #5a667b;
  display: flex;
  align-items: center;
  padding: 16px;
}

.CardReadMore path {
  fill: #5a667b;
  stroke: #5a667b;
}

.WorkflowCardTitle {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  margin: 80px 0 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.WorkflowCommonTitle {
  color: #252626;
  margin: 16px 0 32px;
  font-size: 39px;
  font-weight: 600;
  letter-spacing: -.02em;
  line-height: 49px;
}

.WorkflowCardTitle > br {
    display: none;
  }

.Workflow h1 {
  font-weight: 600;
  font-size: 39.063px;
  line-height: 49px;
  letter-spacing: -0.02em;
  margin-bottom: 32px;
}

.WorkflowsSection {
  display: flex;
  flex-wrap: wrap;
}

.WorkflowsTitleSection {
  display: flex;
  flex-wrap: wrap;
  max-width: 50%;
  max-height: 180px;
  height: max-content;
}

.WorkflowBlock {
  max-height: 180px;
  width: 50%;
  display: flex;
  align-items: center;
  min-height: 53px;
  border-top: 1px solid #dee0e5;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5A667B;
}

.WorkflowBlock img {
  margin-bottom: 2px;
  height: 36px;
  width: 36px;
  transform: rotate(270deg);
}

.WorkflowBlock:hover {
  cursor: pointer;
  color: #ffffff;
  background: #252626;
  border-radius: 4px;
}

.WorkflowBlock:hover img {
  filter: invert(99%) sepia(3%) saturate(584%) hue-rotate(173deg) brightness(999%) contrast(96%);
}

.WorkflowBlockActive {
  color: #ffffff;
  background: #252626;
  border-radius: 4px;
}

.WorkflowBlockActive img {
  filter: invert(99%) sepia(3%) saturate(584%) hue-rotate(173deg) brightness(999%) contrast(96%);
}

.WorkflowsDescriptionSection {
  max-width: calc(50% - 100px);
  font-weight:300;
  font-size: 16px;
  line-height: 24px;
  margin-left: 100px;
  color: #5A667B
}

.WorkflowsDescriptionSection h1 {
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 16px;
  color: #000;
}

.WorkflowProperties {
  padding: 120px 5%;
  background-color: #f6f8f9;
}
.WorkflowProperties h1 {
  font-weight: 600;
  font-size: 31.25px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #252626;
  margin-bottom: 48px;
}

.WorkflowProperties img {
  width: 100%;
}

.AddedValues {
  display: flex;
  padding: 60px 5% 120px;
  height: 753px;
}

.AddedValuesDescriptions {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 0px;
  padding-right: 90px;
}

.AddedValuesDescriptions h1 {
  font-weight: 600;
  font-size: 39px;
  line-height: 49px;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
  color: #252626;
}

.AddedValuesDescriptions div {
  margin-top: 32px;
  display: flex;
  align-items: center;
  line-height: 24px;
}

.AddedValuesDescriptions img {
  margin: 0px 10px auto 0;
}

.AddedValuesImgs {
  width: 50%;
  margin-left: 20px;
}

.AddedValuesImgs img {
  max-height: 50%;
  width: 443px;
  position: relative;
  display: block;
  object-fit: cover;
}

.AddedValuesImgs img:nth-child(2) {
  top: -80px;
  left: 184px;
}
.AddedValuesImgs img:nth-child(2) {
  top: -80px;
  left: 184px;
}

.ArchitectsAndEngineersValues1 {
  z-index: 10;
  width: 443.38px;
}

.ValuesImg {
  height: 100% !important;
  width: 100% !important;
  max-width: 632px !important;
  max-height: unset !important;
}

.WorkflowForeheadForm label {
  font-style: normal;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0 !important;
}

.WorkflowForeheadInformation {
  width: 40%;
}

.WorkflowForeheadInformation label,
.WorkflowForeheadInformation .subheading {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
}

.WorkflowForeheadInformation h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 49px;
  line-height: 61px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: 16px 0 80px;
  width: 90%;
  position: absolute;
}

.WorkflowAdvertising {
  width: 100%;
  padding: 110px 5%;
  background: #252626;
  color: #ffffff;
  display: flex;
}

.AdvertisingTitle {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AdvertisingTitle h1 {
  font-weight: 600;
  font-size: 39.063px;
  line-height: 49px;
  letter-spacing: -0.02em;
}

.AdvertisingTitle p {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  margin: 8px 0 0;
}

.AdvertisingTitle h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}

.p-80 {
  padding: 80px 5%;
}

.BimManagersAndProjectControllersBanner {
  width: 50%;
  max-height: 188px;
  max-width: 610px;
  margin: 0 auto;
}

.BuildersAndProjectDevelopersBanner {
  width: 50%;
  max-height: 420px;
  max-width: 488px;
  margin: 0 auto;
}

.HeaderGif {
  margin-top: 60px;
  max-width: 737px;
  max-height: 425px;
  width: 60%;
  margin-left: 30px;
  object-fit: contain;
}

.ForeheadWithCarousel {
  width: 100%;
  padding: 120px 0;
  background: none;
  flex-direction: column;
  align-items: center;
  height: 847px;
  background-color: #F6F8F9;
}

.PhotosCarousel {
  height: 585px;
  width: 100%;
  transition: translate;
  transition-property: transform;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
}

.PhotosCarouselWindow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: center;
  margin-right: 80px;
  align-items: center;
  transition: translate;
  transition-property: transform;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
  width: max-content;
}

.PhotosCarousel h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 48px;
  text-align: center;
  color: #5A667B;
}

.SliderIndicators {
  height: 8px;
  display: flex;
  flex-direction: row;
  width: 60%;
  transition-property: transform;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
}
.WorkflowSlider .DmsIndicatorActive {
  width: 100%;
  height: 8px;
  background: none;
}
.WorkflowSlider .DmsIndicator {
  margin: 0 3px;
  background-color: #99b8d5;
}

.PhotosCarouseItem {
  transition: width 1s, height 1s;
  margin: 0 16px;
  width: 840px;
}

.DmsPhotoCarouselActive, .DmsPhotoCarouselActive {
  display: flex;
  align-items: center;
  justify-content: center;
}

.WorkflowSlider .DmsPhotoCarouselActive img {
  width: 848px !important;
  max-height: 479px;
  height: unset;
  width: 100%;
  transition: width 1s, height 1s;
  box-shadow: -10px 30px 60px -8px rgb(30 44 101 / 15%), 0px 4px 20px -3px rgb(30 44 101 / 5%);
}

.WorkflowSlider .DmsPhotoCarouselInActive img {
  margin-bottom: 0;
  transition: width 1s, height 1s;
  height: 358px;
  margin-top: 60px;
  object-fit: contain;
  box-shadow: none;
  width: 100%;
}

.WorkflowSlider .DmsIndicatorProgressBar {
  margin-bottom: 0;
  transition: width 1s;
  background-color: #99b8d5;
}

@media (max-width: 1310px) {
  .AddedValuesImgs img {
    width: 410px;
  }
}
@media (max-width: 1250px) {
  .AddedValuesImgs img:nth-child(2) {
    left: 15%;
  }
}

@media (max-width: 1100px) {
  .AddedValuesImgs img {
    max-height: unset;
    max-width: 400px;
  }
}

@media (max-width: 1030px) {
  .AddedValuesImgs img:nth-child(2) {
    top: -10%;
    left: 10%;
  }

  .WorkflowSlider .DmsIndicator {
    margin-right: 0;
  }

  .ForeheadWithCarousel {
    padding: 80px 5%;
    height: 100%;
  }

  .PhotosCarousel h1 {
    font-size: 14px;
    line-height: 21px;
    color: #5A667B;
  }

  .PhotosCarouselWindow {
    height: unset;
    margin-right: 0px;
  }

  .SliderIndicators {
    margin-bottom: 0px;
    width: 100%;
    margin-top: 50px;
  }

  .WorkflowSlider .DmsPhotoCarouselActive img {
    max-width: 848px;
    width: 100% !important;
    margin: 0 !important;
    border-radius: 4px;
    cursor: pointer;
  }
}

@media (max-width: 968px) {
  .WorkflowForeheadInformation label,
  .WorkflowForeheadInformation .subheading {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .WorkflowForeheadInformation h1 {
    margin: 0 0 0 !important;
  }
  .AddedValues {
    padding: 80px 5% !important;
    height: unset;
    flex-direction: column;
    align-items: center;
    padding-top: 0px !important;
  }

  .AddedValuesDescriptions {
    width: unset;
    margin-bottom: 50px;
    padding-right: 0;
  }

  .AddedValuesDescriptions h1 {
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
  }

  .AddedValuesImgs img:nth-child(2) {
    top: -52px;
    left: 171px;
  }

  .AddedValuesImgs {
    width: 100%;
    margin-left: 0;
  }

  .AddedValuesImgs img {
    height: 227px;
    margin-left: 100px;
  }

  .ValuesImg {
    margin: auto !important;
  }
}

@media (max-width: 850px) {
  .WorkflowForeheadInformation p {
    margin-top: 150px;
  }

  .AddedValuesImgs img {
    max-width: 370px;
  }

  .WorkflowCardsList {
    flex-direction: column;
  }

  .WorkflowCard {
    margin-right: 0px;
    margin-bottom: 16px;
  }

  .WorkflowCardTitle {
    font-size: 12px;
    line-height: 18px;
    margin: 60px 0 8px;
  }

  .PhotosCarousel {
    max-height: 500px;
    height: unset;
  }
}

@media (max-width: 768px) {
  .PhotosCarouselWindow {
    width: 100%;
  }

  .PhotosCarouseItem {
    width: 100%;

  }
  .AddedValuesImgs img {
    margin-left: 50px;
  }

  .WorkflowSlider .DmsIndicatorActive {
    height: 5px;
  }

  .WorkflowForeheadInformation h1 {
    font-weight: 600;
    font-size: 31px;
    line-height: 39px;
    margin: 0 !important;
  }

  .AdvertisingTitle {
    width: 100%;
    text-align: center;
  }

  .WorkflowCommonTitle {
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    margin: 8px 0 24px;
  }

  .WorkflowAdvertising {
    padding: 55px 5%;
    flex-direction: column;
  }

  .BimManagersAndProjectControllersBanner {
    width: 100%;
    margin-top: 75px;
  }

  .BuildersAndProjectDevelopersBanner {
    width: 100%;
    margin-top: 50px;
  }

  .HeaderGif {
    margin: 0 auto;
    margin-top: 64px;
    max-width: 300px;
    max-height: 500px;
    width: 100%;
  }

  .Workflow h1 {
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
  }

  .WorkflowsTitleSection {
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 100%;
    max-height: unset;
  }

  .WorkflowBlock {
    max-height: unset;
    width: 100%;
    height: unset;

  }

  .WorkflowBlockMobile {
    height: fit-content !important;
  }

  .WorkflowBlockActive img {
    margin-bottom: auto;
    margin-top: 10px;
    height: 32px;
  }

  .WorkflowBlock h1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 16px;
  }

  .WorkflowBlock div {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    margin: 16px 10px 16px 0;
  }

  .WorkflowProperties {
    padding: 80px 5% !important;
  }

  .WorkflowProperties h1 {
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
  }

  .WorkflowForehead {
    padding: 56px 5%;
    width: 100%;
    flex-direction: column;
    height: 100%;
  }

  .WorkflowForeheadInformation {
    width: 100%;
  }

  @media (max-width: 650px) {
    .AddedValuesImgs img {
      margin-left: 0px;
    }
  }

  @media (max-width: 580px) {
    .AddedValuesImgs img {
      width: 229px;
      height: 155px;
      margin-left: 70px;
    }

    .AddedValuesImgs img:nth-child(2) {
      top: -35px;
      left: 98px;
    }
  }

  @media (max-width: 440px) {
    .AddedValuesImgs img {
      margin-left: 20px;
    }
  }

  @media (max-width: 380px) {
    .WorkflowForeheadInformation p {
      margin-top: 150px;
    }
    .AddedValuesImgs img {
      width: 179px;
      height: 105px;
      margin-left: 30px;
      max-width: 243px;
    }

    .AddedValuesImgs img:nth-child(2) {
      top: -30px;
      left: 76px;
    }
  }

  @media (max-width: 320px) {
    .AddedValuesImgs img {
      margin-left: 0px;
    }
  }
}
