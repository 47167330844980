.DmsForehead{
    position: relative;
    padding: 0;
    height: 970px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    color: #FFFFFF;
    background: linear-gradient(131.57deg, #004D95 0%, #002E59 100%);
}

.DmsForeheadInformation h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin: 0;
}

.DmsForeheadInformation .subheading {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: unset;
    color: #FFFFFF;
    margin: 16px 0 54px;
}

.DmsForeheadForm {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.DmsForeheadForm p{
    margin: 0 0 -7px 0;
}

.DmsForeheadForm input {
    flex-direction: row;
    align-items: flex-start;
    padding: 13px 16px;
    gap: 10px;
    width: 309px;
    height: 42px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    border-radius: 3px;
    border: none;
    outline: none;
    margin-top: 15px;
}

.DmsForeheadForm input::placeholder {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
}

.DmsForeheadInformation {
    width: 40%;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding: 80px 43px 120px 5%;
}

.DmsForeheadInformation h1{
    font-style: normal;
    font-weight: 600;
    font-size: 48.828px;
    line-height: 61px;
    letter-spacing: -0.02em;
}


.DmsForeheadForm button{
    align-items: center;
    padding: 11.5px 16px;
    margin-top: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    width: 113px;
    height: 48px;
    background: #FFBE28;
    border-radius: 4px;
}

.DmsForeheadWithCarousel {
    width: 60%;
    box-sizing: border-box;
    height: 968px;
    background: linear-gradient(41.57deg, #004D95 0%, #002E59 100%);
    mix-blend-mode: normal;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.DmsSliderIndicators{
    width:8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 456px;
    margin: 0 16px 0 84px;
    transition-property: transform;
    transition-duration: 1000ms;
    transition-timing-function: ease-in-out;
}

.DmsIndicator{
    width:8px;
    height: 8px;
    background: #3371AA;
    border-radius: 4px;
    transition: width 1s, height 1s;
}

.DmsIndicatorActive{
    width: 8px;
    height: 408px;
    border-radius: 4px;
    transition: width 1s, height 1s, background 1s;
    background: #3371AA;
    flex-direction: column;
    display: flex;
    overflow:hidden;
}
.DmsIndicatorProgressBar{
    width:8px;
    height: 0px;
    border-radius: 4px;
    background: white;
}
@keyframes progress {
    from {
        height: 0;
    }
}


.DmsPhotosCarousel{
    overflow: hidden;
}

.DmsPhotosCarouselWindow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 80px;
    align-items: flex-end;
    transition: translate;
    transition-property: transform;
    transition-duration: 1000ms;
    transition-timing-function: ease-in-out;
}

.DmsPhotoCarouselInActive img{
    width: 416px;
    height: 272px;
    box-shadow: -15px 25px 50px -8px rgba(37, 38, 38, 0.4), 0 4px 20px -3px rgba(37, 38, 38, 0.1);
    border-radius: 4px;
    margin-bottom: 32px;
    transition: width 1s, height 1s;
}

.DmsPhotoCarouselActive img{
    width: 632px;
    height: 408px;
    box-shadow: -15px 25px 50px -8px rgba(37, 38, 38, 0.4), 0 4px 20px -3px rgba(37, 38, 38, 0.1);
    border-radius: 4px;
    margin-bottom: 32px;
    transition: width 1s, height 1s;
}

.Possibilities {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 0 5%;
}

.PossibilitiesListBlock {
    display: flex;
    flex-direction: column;
    padding: 140px 0;
    width: 42%;
    background: #FFFFFF;
    box-shadow: 30px 0px 40px -20px rgba(30, 44, 101, 0.05);
}


.PossibilitiesTitle h2 {
    width: 90%;
    font-style: normal;
    font-weight: 600;
    font-size: 48.828px;
    line-height: 61px;
    letter-spacing: -0.02em;
    color: #252626;
    margin: 0 0 64px;
}

.PossibilitiesList ul{
    width: 505px;
    height: 25px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 16px 8px 16px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #5A667B;
}

.PossibilitiesList ul:hover{
    width: 505px;
    height: 25px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 16px 8px 16px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    background: #4B6FFC;
    color: #FFFFFF;
    border-radius: 2px 0 0 2px;
}

.PossibilitiesPhotos {
    width: 58%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 801px;
    padding: 140px 0 140px 10%;
    box-sizing: border-box;
}

.Communicate {
    padding: 120px 5%;
    width: 100%;
    height: auto;
    background: #F6F8F9;
}

.Communicate h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 39px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #252626;
    margin-bottom: 8px;
}

.Communicate .subheading{
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    color: #252626;
    text-align: center;
    margin-bottom: 64px;
}

.CommunicateFunctions {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Function {
    flex-direction: column;
    align-items: center;
    padding: 48px 48px 56px;
    border-top: 4px solid;
    margin: 0;
    width: 30%;
    height: auto;
    background: #FFFFFF;
    position: relative;
    box-shadow: 0 8px 30px -10px rgba(30, 44, 101, 0.12), 0 2px 6px -1px rgba(30, 44, 101, 0.05);
    border-radius: 4px;
}

.BorderColorFirst::after{
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    display: block;
    height: 4px;
    content: '';
    z-index: 111;
    background: linear-gradient(131.57deg, #3DD1DC 0%, #1A9AA4 100%);
    border-radius: 4px 4px 0px 0px;
}

.BorderColorSecond::after{
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    display: block;
    height: 4px;
    content: '';
    z-index: 111;
    background: linear-gradient(131.57deg, #AA007B 0%, #66004A 100%);
    border-radius: 4px 4px 0px 0px;
}

.BorderColorThird::after{
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    display: block;
    height: 4px;
    content: '';
    z-index: 111;
    background: linear-gradient(131.57deg, #004D95 0%, #002E59 100%) ;
    border-radius: 4px 4px 0px 0px;
}

.FunctionSubtitle{
    margin: 11px 0 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #252626;
    padding:0;
}

.FunctionText{
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #252626;
}

.ContainerContent{
    margin:auto;
    width: 1280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 96px;
}

.DmsContainer1{
    width: 100%;
    height: 712px;
}

.ContainerImage{
    margin: 120px 0 120px 0;
    box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0 4px 20px -3px rgba(30, 44, 101, 0.05);
    border-radius: 4px;
}
.ContainerImage img{
    width: 738px;
    height: 472px;
    box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0 4px 20px -3px rgba(30, 44, 101, 0.05);
}

.ImageShadow{
    box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0px 4px 20px -3px rgba(30, 44, 101, 0.05);
}

.ContainerGray{
    background: #F6F8F9;
}

.ContainerText{
    width: 400px;
}

.FirstContainer{
    margin-right: 7.5%;
}
.SecondContainer{
    margin-left: 7.5%;
}

.ContainerText h4{
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    alignment: left;
    margin: 0 0 24px 0;
    color: #252626;
}

.ContainerText p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5A667B;
    margin: 0 0 0 0;
}

.DmsContainer2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 96px;
    width: 100%;
    height: 652px;
    background: #F6F8F9;
}

.DMSPossibilitiesList {
    padding: 0;
    margin: 0;
    list-style: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    box-sizing: border-box;
}

.DMSPossibilitiesList li {
    border-radius: 2px 0 0 2px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.PossibilitiesPhotoSmallScreen {
    display: none;
}

@media (max-width: 1400px) {
    .DmsPhotosCarouselWindow {
        margin-right: 50px;
    }
}

@media (max-width: 1300px) {

    .DmsPhotoCarouselInActive img {
        width: 316px;
        height: 242px;
        box-shadow: -15px 25px 50px -8px rgba(37, 38, 38, 0.4), 0 4px 20px -3px rgba(37, 38, 38, 0.1);
        border-radius: 4px;
        margin-bottom: 32px;
        transition: width 1s, height 1s;
    }

    .DmsPhotoCarouselActive img {
        width: 532px;
        height: 378px;
        box-shadow: -15px 25px 50px -8px rgba(37, 38, 38, 0.4), 0 4px 20px -3px rgba(37, 38, 38, 0.1);
        border-radius: 4px;
        margin-bottom: 32px;
        transition: width 1s, height 1s;
    }

}

@media (max-width: 1200px) {
    .DmsSliderIndicators {
        margin: 0 16px 0 24px;
    }

    .Possibilities {
        padding: 0 2%;
    }

    .PossibilitiesPhotos {
        width: 60%;
        height: auto;
        padding: 100px 0 0 2%;
        box-sizing: border-box;
    }
}

@media (max-width: 1050px) {
    .DmsPhotosCarouselWindow {
        margin-right: 10px;
    }
}

@media (max-width: 1000px) {
    .DmsPhotoCarouselActive img {
        width: 430px;
        height: 308px;
        box-shadow: -15px 25px 50px -8px rgba(37, 38, 38, 0.4), 0 4px 20px -3px rgba(37, 38, 38, 0.1);
        border-radius: 4px;
        margin-bottom: 32px;
    }

    .DmsForeheadInformation {
        width: 40%;
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        padding: 120px 23px 120px 30px;
    }

    .DmsPhotosCarouselWindow {
        margin-right: 40px;
    }
}

@media (max-width: 900px) {
    .DmsPhotosCarouselWindow {
        margin-right: 5px;
    }
}

@media (max-width: 768px) {
    .FunctionSubtitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .FunctionText {
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
    }

    .DmsForehead {
        display: block;
        height: 1000px;
        padding-bottom: 220px;
    }

    .DmsForeheadWithCarousel {
        height: 270px;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        background: none;
    }


    .DmsSliderIndicators {
        height: 4px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 0 8%;
        margin: 0 auto 25px;
    }

    .DmsIndicator {
        width: 8px;
        height: 6px;
        margin-right: 5px;
    }

    .DmsIndicatorActive {
        height: 5px;
        margin: 0.5px 5px 0;
        width: 408px;
    }

    .DmsPhotosCarouselWindow {
        width: 100%;
        height: 190px;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0;
    }

    .DmsPhotoCarouselActive img {
        height: 190px;
        width: 326px;
        margin-bottom: 0;
        margin-right: 32px;
    }

    .DmsPhotoCarouselInActive img {
        width: 255px;
        height: 160px;
        margin-bottom: 0;
        margin-right: 32px;
    }

    .PossibilitiesPhotoSmallScreen {
        display: block;
    }

    .PossibilitiesPhotos {
        display: none;
    }

    .PossibilitiesListBlock {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .PossibilitiesPhotoSmallScreen img {
        width: 326px;
        height: 186px;
        box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0px 4px 20px -3px rgba(30, 44, 101, 0.05);
        border-radius: 4px;
    }

    .Possibilities {
        padding: 80px 0 50px;
    }

    .PossibilitiesTitle h2 {
        margin: 0 32px 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 31px;
    }

    .DMSPossibilitiesList li {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #5A667B;
        border-top: 0.5px solid #BDC2CA;
        padding-left: 8%;
        padding-right: 8%;
    }

    .DMSPossibilitiesList:last-child {
        border-bottom: 0.5px solid #BDC2CA;
    }

    .Communicate {
        padding: 80px 8%;
        width: 100%;
        height: auto;
        background: #F6F8F9;
        box-sizing: border-box;
    }

    .CommunicateFunctions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: auto;
    }

    .Communicate h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 31px;
        text-align: left;
    }

    .Communicate .subheading {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-top: 0;
        margin-bottom: 40px;
        padding: 0;
        margin-left: 0;
    }

    .Function {
        flex-direction: column;
        align-items: center;
        padding: 32px 24px;
        border-width: 4px;
        margin: 0 0 16px;
        width: auto;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0 8px 30px -10px rgba(30, 44, 101, 0.12), 0 2px 6px -1px rgba(30, 44, 101, 0.05);
    }

    .DmsForeheadInformation {
        width: 100%;
        position: relative;
        z-index: 1;
        padding: 56px 8%;
        margin: 0;
    }
    .DmsForeheadInformation h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 31px;
        line-height: 39px;
    }

    .DmsForeheadInformation .subheading {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0 40px;
    }
}

@media (max-width: 400px) {
    .DmsForeheadInformation {
        padding: 56px 6%;
    }

    .Communicate {
        padding: 80px 6%;
    }
}

@media (min-width: 1520px) {
    .DmsPhotosCarouselWindow {
        margin-right: 150px;
    }
}

@media (min-width: 1650px) {
    .DmsPhotosCarouselWindow {
        margin-right: 220px;
    }
}

@media (min-width: 1750px) {
    .DmsPhotosCarouselWindow {
        margin-right: 300px;
    }
}

@media (min-width: 1920px) {
    .DmsPhotosCarouselWindow {
        margin-right: 300px;
    }
    .Communicate {
        padding: 120px 10%;
    }
    .Possibilities{
        padding: 0 10%;
    }
    .DmsForeheadInformation {
        width: 46%;
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        padding: 80px 43px 120px 6%;
    }

}