.ContactUsWrapper {
    height: 600px;
    overflow: hidden;
}

.ContactUsContainer {
    background-image: 
                      url('../../assets/Homepage/webp/CTABackground.png'),
                      url('../../assets/Homepage/webp/CTABackground.webp'),
                      url('../../assets/Homepage/webp/CTABackground.jpg');
    position: relative;
    background-repeat: no-repeat;
    height: 610px;
    top: -2px;
    overflow: hidden;
    background-size: 100% 110%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 77px;
    color: #FFFFFF;
}

.ContactUsBackgroundPhoto {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(115.51deg, rgba(37, 38, 38, 0) 52.61%, rgba(0, 0, 0, 0.85) 200.67%);
    mix-blend-mode: multiply;
    left: 0;
    top: 0;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.ContactUsContainer h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    width: 40%;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
}

.ContactUsFormInputContainer {
    gap: 8px;
}

.ContactUsFormInputContainer input {
    width: max-content;
}

.ContactUsForm {
    background: rgba(0, 77, 149, 0.3);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    width: 40%;
    padding: 32px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.ContactUsForm h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    padding: 0;
}

.ContactUsForm p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 4px 0 15px;
    padding: 0;
}

.ContactUsForm textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 98px;
    border: none;
    outline: none;
    resize: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 15px 20px;
    color: #FFFFFF;
}

.ContactUsForm input {
    color: #FFFFFF;
    display: block;
    width: 49.36%;
    height: 48px;
    box-sizing: border-box;
    padding: 11px 20px;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

.ContactUsForm input::placeholder, textarea::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    width: fit-content;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}

.ContactUsForm button {
    width: fit-content;
    box-sizing: border-box;
    padding: 12px 16px;
    background: #FFBE28;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.02em;
    color: #003E77;
    margin-top: 8px;
    float: right;
    height: 48px;
}

.ContactUsForm button:hover {
    color: #FFBE28;
    background: inherit;
    outline: 1px solid #FFBE28;
}

@media (max-width: 968px) {
    .ContactUsContainer {
        height: 650px;
        box-sizing: border-box;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 15.35%, rgba(0, 0, 0, 0) 100%), url('../../assets/Homepage/webp/CTABackground.webp'), url('../../assets/Homepage/webp/CTABackground.jpg');
        background-size: auto;
        display: block;
        flex-direction: column;
        align-items: center;
        justify-content: start !important;
        padding: 80px 5%;
        color: #FFFFFF;
        top: -1px;
    }
    .ContactUsWrapper {
        height: 598px;
        overflow: hidden;
    }

    .ContactUsContainer h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 31px;
        line-height: 39px;
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
    }

    .ContactUsForm {
        background: rgba(0, 77, 149, 0.4);
        backdrop-filter: blur(15px);
        border-radius: 10px;
        padding: 32px;
        box-sizing: border-box;
        margin-top: 32px;
        width: 100%;
        height: 350px;
    }

    .ContactUsForm h6 {
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .ContactUsForm p {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin: 5px 0 16px;
        padding: 0;
    }

    .ContactUsForm textarea {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        border: none;
        outline: none;
        resize: none;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(15px);
        border-radius: 4px;
        padding: 11px 20px;
    }

    .ContactUsForm input {
        display: inline-block;
        width: 100%;
        height: 48px;
        box-sizing: border-box;
        padding: 11px 20px;
        border: none;
        outline: none;
        margin-bottom: 8px;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(15px);
        border-radius: 4px;
    }

    .ContactUsForm input::placeholder, textarea::placeholder {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
    }

    .ContactUsForm button {
        width: 84px;
        height: 48px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .ContactUsBackgroundPhoto {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 15.35%, rgba(0, 0, 0, 0) 100%);
    }
}

@media (min-width: 1920px) {
    .ContactUsContainer {
        padding: 0 10%;
    }
}