.PrivacyNoticeForehead {
    display: flex;
    background: #F6F8F9;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
}

.PrivacyNoticeForeheadData {
    width: 50%;
    padding: 64px 5% 120px;
}

.PrivacyNoticeForeheadData h1 {
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
}

.PrivacyNoticeForeheadData .subheading {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin: 36px 0 64px;
    padding: 0;
}

.PrivacyNoticeParagraph {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #5A667B;
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
}

.PrivacyNoticeParagraph a {
    color: inherit;
}

.PrivacyNoticeBoldParagraph {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #5A667B;
    margin: 24px 0;
    padding: 0;
}

.PrivacyNoticeLinkForehead {
    margin: 24px 0;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #4B6FFC;
}

.PrivacyNoticeBlockInfo {
    margin: 80px 5% 0;
}

.PrivacyNoticeBlockInfo h4 {
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    margin: 0 0 24px;
}

.PrivacyNoticeSubParagraph {
    margin-bottom: 36px;
}

.PrivacyNoticeSubParagraph .subheading {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #4B6FFC;
    margin: 0 0 8px;
    padding: 0;
}

.PrivacyNoticeText {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #5A667B;
    margin: 0;
}

.PrivacyNoticeBlockInfo ul, .PrivacyNoticeBlockInfo li {
    margin: 0;
    font-size: 14px;
    line-height: 21px;
    color: #5A667B;
}

.PrivacyNoticeBlockInfo ul {
    margin: 15px 0;

}

.PrivacyNoticeLabelForList {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #5A667B;
    margin-top: 10px;
    display: block;
}

.PrivacyNoticeFormatedText {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #5A667B;
    margin: 0;
    white-space: pre-wrap;
}

.PrivacyNoticeCustomList {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    padding: 0 20px;
}
.PrivacyNoticeCustomList li a{
    color:inherit;
}
.PrivacyNoticeFormatedText .notColorA{
    color:inherit;
}

.PrivacyNoticeSubParagraphWithMarginTop {
    margin-top: 24px;
}

.PrivacyNoticeEmailInfo p {
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    color: #5A667B;
    font-weight: 600;
}

.FinishText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: 80px 5% 120px;
}

.PrivacyNoticeFormatedText a {
    text-decoration: underline;
    color: #4B6FFC;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .PrivacyNoticeParagraph {
        white-space: normal;
    }

    .PrivacyNoticeForehead {
        flex-direction: column;
        align-items: center;
    }

    .PrivacyNoticeForeheadData {
        width: 80%;
        margin: 0 auto;
        padding: 64px 5% 120px;
    }
}
@media (max-width:968px){
    .PrivacyNoticeForeheadData {
        width: 100%;
        padding: 56px 10% 80px;
    }

    .PrivacyNoticeForeheadData h1 {
        font-weight: 600;
        font-size: 31px;
        line-height: 39px;
    }

    .PrivacyNoticeForeheadData .subheading {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0 36px;
        padding: 0;
    }

    .PrivacyNoticeLinkForehead {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #4B6FFC;
    }

    .PrivacyNoticeParagraph {
        white-space: pre-wrap;
    }

    .PrivacyNoticeForehead img {
        height: 382px;
        width: 100%;
    }

}