.CareerForehead {
    display: flex;
    justify-content: space-between;
    height: 511px;
    overflow: hidden;
}

.CareerForehead img {
    height: 567px;
    position: relative;
    margin-top: -52px;
    width: 716px;
}

.CareerForeheadData {
    margin: 64px 5.56% 0;
    width: 38%;
}

.CareerForeheadData h1 {
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    letter-spacing: -0.02em;
}

.CareerForeheadData p {
    font-weight: 300;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: -0.02em;
    width: 76%;
    margin: 48px 0;
    padding: 0;
}

.CareerVacancies {
    padding: 120px 5.56%;
    background: #F6F8F9;
}

.CareerNumberVacancies {
    font-weight: 300;
    font-size: 22px;
    line-height: 31px;
    color: #5A667B;
    border-bottom: 1px solid #DEE0E5;
    padding: 0 0 16px 0;
    margin: 0;
    display: flex;
    gap: 8px;
}

.CareerNotFoundVacancies {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CareerNotFoundVacancies p {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 29px;
    line-height: 39px;
    font-weight: 200;
}

.CareerNotFoundVacancies b {
    font-size: 31px;
    font-weight: 500 !important;
}

.CareerNotFoundVacancies button {
    margin-top: 24px;
    min-width: 103px;
    width: fit-content;
    padding: 13px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    height: auto;
}
.CareerNotFoundVacancies button:hover {
    background: inherit;
    color: #4B6FFC;
    outline: 1px solid #4B6FFC;
}

.CareerVacancy {
    padding: 48px 32px;
    position: relative;
    border-top: 1px solid #DEE0E5;
}

.CareerVacancy:first-child {
    border-top: 1px solid transparent;;
}

.CareerVacancy:last-child {
    border-bottom: 1px solid #DEE0E5;
}

.CareerVacancy:hover + .CareerVacancy {
    border-top: 1px solid transparent;
}

.CareerVacancy:hover {
    background: #FFFFFF;
    box-shadow: 0 8px 30px -10px rgba(30, 44, 101, 0.12), 0 2px 6px -1px rgba(30, 44, 101, 0.05);
    border-radius: 4px;
    border-bottom: 1px solid transparent;;
    border-top: 1px solid transparent;;
}



.CareerVacancy a {
    color: #4B6FFC;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
}

.CareerVacancy a:hover {
    text-decoration: underline;
}

.CareerVacancy a svg {
    top: -2px !important;
}

.CareerApplyNow {
    position: absolute;
    right: 32px;
    top: 48px;
    width: fit-content;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    height: auto;
    padding: 13px 16px;
}

.CareerApplyNow:hover {
    background: inherit;
    color: #4B6FFC;
    padding: 12px 15px;
    border: 1px solid #4B6FFC;
}


.CareerBoldTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 12px;
    padding: 0;
}

.CareerTags {
    gap: 8px;
    display: flex;
    margin-top: 20px;
}

.CareerTags span {
    padding: 4px 16px;
    border: 1px solid #5A667B;
    border-radius: 20px;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #5A667B;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.CareerVacancyDescription {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #5A667B;
    margin: 20px 0 6px;
    padding: 0;
}

@media (max-width: 1300px) {
    .CareerForehead img {
        height: 467px;
        position: relative;
        margin: auto 0;
        width: 516px;
    }

    .CareerForehead {
        height: fit-content;
    }
}

@media (max-width: 968px) {
    .CareerNumberVacancies{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .CareerVacancies {
        padding: 80px 8%;
        background: #F6F8F9;
    }
    .CareerForehead {
        flex-direction: column;
        justify-content: normal;
    }

    .CareerForehead img {
        height: 567px;
        position: relative;
        margin: 0 auto;
        width: 716px;
    }

    .CareerForeheadData {
        margin: 0;
        padding: 72px 8.2%;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
    }

    .CareerForeheadData h1 {
        font-weight: 600;
        font-size: 31px;
        line-height: 39px;
        letter-spacing: -0.01em;
    }

    .CareerForeheadData p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
        width: 100%;
        margin: 16px 0 0;
        padding: 0;
    }

    .CareerApplyNow {
        position: static;
        width: fit-content;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        height: auto;
        padding: 13px 16px;
        margin: 20px 0 0 auto;
    }

    .CareerNotFoundVacancies p {
        margin: 0;
        padding: 0;
        text-align: left;
        font-weight: 300;
        font-size: 22px;
        line-height: 28px;
        width:95%;
    }
    .CareerNotFoundVacancies b{
        font-size: 22px;
        font-weight: 300;
    }

    .CareerNotFoundVacancies {
        margin-top: 80px;
        align-items: flex-start;
    }
}

@media (max-width: 750px) {
    .CareerForehead img {
        width: 100%;
        height: auto;
    }

    .CareerVacancy {
        padding: 36px 16px;
    }
}