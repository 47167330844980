.Header {
    height: 57px;
    padding: 0 7.5%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 102;
    background: #FFFFFF;
}

.HeaderDropdownMenuMobile {
    display: none;
}

.NavMenu {
    display: flex;
    justify-content: space-between;
    width: 50%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.NavMenu li {
    list-style: none;
    cursor: pointer;
    letter-spacing: 0.02em;
    font-weight: 500;
    font-size: 14px;
    height: 55px;
    line-height: 21px;
    color: #252626;
    display: flex;
    align-items: center;
    padding: 0 6px;
}

.NavMenu li a {
    color: #252626;
    text-decoration: none;
}

.NavMenuElement:hover {
    border-bottom: 2px solid #4B6FFC;
}

.NavMenu li img {
    top: -2px;
    position: relative;
}

.Header_Login_Part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    width: 15%;
    position: relative;
}

.Header_Login_Part img {
    cursor: pointer;
}


button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    width: 71px;
    height: 40px;
    background: #4B6FFC;
    color: #FFFFFF;
    border-radius: 4px;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    cursor: pointer;
}

button a {
    text-decoration: none;
    color: inherit;
}

.Header_Login_Part button {
    background: white;
    color: #4B6FFC;
}

.Header_Login_Part button:hover {
    outline: 1px solid #4B6FFC;
}


.line {
    border: 1px solid #D2E2EE;
    width: 0;
    height: 25px;
}

.SelectLanguage {
    display: none;
}

.SelectLanguageActive {
    display: block;
    position: absolute;
    z-index: 10;
    background-color: #FFFFFF;
    width: 74px;
    bottom: -80px;
    right: -30px;
    padding: 5px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
}

.SelectLanguageActive li {
    list-style: none;
    margin: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
}

.SelectLanguageActive li:hover {
    font-weight: 600;
}

.LanguageWrapper {
    position: relative;
}

.SelectFeatures {
    margin: 0;
    padding: 0;
    display: block;
    position: absolute;
    z-index: 10;
    background-color: #FFFFFF;
    width: 170px;
    top: 40px;
    left: -10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
}

.SelectFeatures li {
    padding: 10px;
}

.SelectFeatures li:hover {
    background-color: #4B6FFC;
    color: #FFFFFF;
}

.Header_Login_image {
    display: none;
}

.HeaderBigFeaturesMenu {
    position: absolute;
    width: 100vw;
    left: 0;
    /* top: 57px; */
    top: 0;
    margin-top: 57px;
    height: 668px;
    background: rgba(37, 38, 38, 0.99);
    backdrop-filter: blur(40px);
    border-radius: 0 0 10px 10px;
    cursor: default;
}

.FeaturesMenuColumn {
    width: 26%;
    display: block;
    margin: 64px 0 0 5%;
}

.FeaturesMenuColumn ul {
    margin: 36px 0 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.FeaturesMenuColumn li {
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    gap: 6px;
    height: auto;
}

li.FeaturesMenuColumnActive {
    border: none;
    align-items: flex-start;
}

.FeaturesMenuColumn h3 {
    font-weight: 500;
    font-size: 31px;
    line-height: 39px;
    color: #FFFFFF;
    letter-spacing: -0.01em;
}

.elementFeaturesMenuBlock a span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.elementFeaturesMenuBlock > a span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.elementFeaturesMenuBlock a p {
    max-width: 268px;
    margin: 4px 0 0;
    padding: 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    text-transform: none;
}

.MenuWrappers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.FeaturesGraphic {
    margin-top: 80px;
}

.WorkflowMenuColumn {
    width: 28%;
    padding: 64px 0 0 80px;
    background: #252626;
    border-radius: 0 0 10px 0;
    height: 668px;
}

.WorkflowMenuColumn h3 {
    font-weight: 500;
    font-size: 31.25px;
    line-height: 39px;
    color: #FFFFFF;
}

.WorkflowMenuColumn ul {
    margin: 30px 0 0;
    padding: 0;
}

.WorkflowMenuColumn li {
    color: #FFFFFF;
    list-style: none;
    height: 48px;
    border-bottom: 0.5px solid #363D4A;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.02em;
    padding: 10px 0 10px 6px;
}

.WorkflowMenuColumn li a {
    color: #FFFFFF;
    list-style: none;
    height: 48px;
    border-bottom: 0.5px solid #363D4A;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.02em;
    padding: 10px 0 10px 6px;
}
.WorkflowMenuColumn li a:hover{
    color:#FFBE28;
}

.WorkflowMenuColumn ul li a img {
    width: 36px;
    height: 36px;
    margin-right: 10px;
}

@media (max-width: 1100px) {
    .FeaturesMenuColumn {
        margin: 64px 0 0 3%;
        width: 20%;
    }

    .WorkflowMenuColumn {
        padding-left: 20px;
    }
}

@media (max-width: 768px) {
    .NavMenu {
        display: none;
    }

    .HeaderBigFeaturesMenu {
        display: none;
    }

    .Header {
        height: 48px;
        padding: 0 16px 0 32px;
    }

    .Header_Login_Part {
        width: 30%;
        position: relative;
        padding: 0;
    }

    .line {
        display: none;
    }

    .LanguageWrapper {
        display: none;
    }

    .Header_Login_Part button {
        color: white;
        background: #4B6FFC;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        width: 66px;
        height: 32px;
    }

    .Header_Login_image {
        display: block;
    }

    .HeaderDropdownMenuMobile {
        display: block;
        background: rgba(37, 38, 38, 0.95);
        backdrop-filter: blur(20px);
        position: absolute;
        width: 100vw;
        top: 48px;
        left: 0;
        color: #FFFFFF;
        overflow: scroll;
    }

    .HeaderDropdownMenuMobile ul {
        padding: 16px 32px 16px;
        list-style: none;
        margin: 0;
        border-bottom: 0.5px solid #5A667B;
    }

    .HeaderDropdownMenuMobile ul label {
        font-weight: 500;
        font-size: 15px;
        line-height: 33px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        display: flex;
        align-items: center;
    }

    .HeaderDropdownMenuMobile ul li {
        margin-top: 8px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0;
    }

    .HeaderDropdownMenuMobile ul li a {
        color: #fff;
        text-decoration: none;
    }

    .HeaderFlexContainerList {
        padding: 16px 32px 16px;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.5px solid #5A667B;
    }

    .HeaderFlexContainerList ul {
        padding: 0;
        margin: 0;
        border: none;
    }

    .HeaderFlexContainerList ul li {
        margin-top: 13px;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }

    .HeaderFlexContainerList ul li a {
        color: #fff;
        text-decoration: none;
    }

    .HeaderFlexContainerList ul label {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }

    .HeaderSocialMediaContainer {
        display: flex;
        padding: 16px 32px;
        justify-content: space-between;
        align-items: center;
    }

    .HeaderSocialMediaContainer img {
        width: 20px;
        height: 20px;
    }

    .HeaderDropdownMenuMobilelanguage {
        width: 57px;
        height: 23px;
        position: absolute;
        top: 24px;
        right: 36px;
        z-index: 9999;
        display: flex;
        justify-content: space-between;
    }

    .HeaderDropdownMenuMobilelanguage div {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        cursor: pointer;
    }

    .HeaderDropdownMenuMobilelanguage div:first-child {
        border-right: 0.5px solid #FFFFFF;
        padding-right: 8px;
    }
}
@media(min-width:1600px){
    .FeaturesMenuColumn {
        width: 24%;
    }
}
@media(min-width:1700px){
    .FeaturesMenuColumn {
        width: 20%;
    }
}
@media(min-width:1920px){
    .Header {
        padding: 0 11%;
    }
    .MenuWrappers{
        padding: 0 6.6%;
    }
}
