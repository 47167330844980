.IFCForehead {
    padding: 80px 5%;
    position: relative;
    height: 968px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

.IFCBACK {
    position: absolute;
    width: 65%;
    background: linear-gradient(186.68deg, #242931 9.24%, #121419 94.07%);
    clip-path: polygon(18% 0%, 100% 0%, 100% 100%, 20% 100%, 6% 50%);
    height: 100%;
    right: 0;
    top: 0;
}

.IFCForeheadData {
    width: 33%;
}

.IFCForeheadData h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    letter-spacing: -0.02em;
    margin: 8px 0 16px;
    width:100%;
}

.IFCForeheadData .subheading {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0;
    margin: 0 0 80px;
    width:88%;
}


.IFCForeheadIllustration {
    width: 63%;
    padding-top: 8px;
    display: flex;
    justify-content: flex-end;

}

.IFCForeheadIllustrationBackground {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.IFCForeheadIllustrationGraphic {
    position: relative;
    z-index: 1;
    width: 696px;
    height: 674px;
}

.IFCForeheadForm label {
    color: #252626;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
}

.IFCForeheadForm input {
    display: block;
    padding: 12px 16px;
    min-width: 275px;
    border: 1px solid #9CA3B0;
    color: #252626;
}

.IFCForeheadForm input::placeholder {
    color: #9CA3B0;
}

.IFCForeheadForm button {
    background: #4B6FFC;
    width: fit-content;
    color: #FFFFFF;
}
.IFCForeheadForm button:hover{
    background:#FFFFFF;
    color: #4B6FFC;
    border: 1px solid #4B6FFC;
}

.IFCPowerVisoplan {
    padding: 120px 5%;
    background: #5A667B;
    color: #FFFFFF;
}

.IFCPowerVisoplan h2 {
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    text-align: center;
    display: block;
    margin: 0 0 64px;
    padding: 0;
}

.IFCFlexContainer {
    display: flex;
    justify-content: space-between;
    padding: 80px 5%;
}

.IFCFlexContainerData {
    width: 35%;
    margin: auto 0;
}

.IFCFlexContainerData label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.IFCFlexContainerData h4,.IFCFlexContainerData h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    margin: 0;
    padding: 0;
}

.IFCFlexContainerData p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin: 24px 0 0;
    padding: 0;
    color: #5A667B;
}

.IFCFlexContainerIllustration {
    width: 739px;
    height: 472px;
    border-radius: 4px;
    box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0px 4px 20px -3px rgba(30, 44, 101, 0.05);
}

.IFCFlexContainerIllustrationBigger {
    width: 760px;
}

.IFCFlexContainerReverse .IFCFlexContainerIllustration {
    box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0px 4px 20px -3px rgba(30, 44, 101, 0.05);
}

.IFCWebAsDesktopVersion {
    display: flex;
    justify-content: space-between;
    background: #F6F8F9;
    height: 700px;
}

.IFCWebAsDesktopVersionData {
    margin: auto 0 auto 5%;
}

.IFCWebAsDesktopVersionData h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    letter-spacing: -0.02em;
}

.IFCWebAsDesktopVersionData .subheading {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    margin: 24px 0 48px;
}

.IFCWebAsDesktopVersionData ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.IFCWebAsDesktopVersionData ul li {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 4px 16px;
    border-radius: 20px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 12px;
}

.IFCWebAsDesktopVersionData ul li img {
    margin-right: 10px;
}

.IFCWebAsDesktopVersionIllustration {
    width: 827px;
    height: 500px;
    margin: auto 0;
}

.IFCForeheadIllustrationBackgroundSmallScreen {
    display: none;
}

.IFCForeheadIllustrationGraphicSmallScreen {
    display: none;
}

.AnimatedBlock {
    display: flex;
    justify-content: space-between;
    padding: 80px 5%;
    position: relative;
    height: 3260px;
}

.AnimatedBlockData {
    width: 35%;
    padding: 130px 0;
}

.AnimatedBlockDataElement {
    width: 100%;
    height: 632px;
}

.AnimatedBlockIllustration {
    height: 3060px;
}

.AnimatedBlockContainerIllustration {
    position: sticky;
    top: 140px;
    animation: 2s show ease;
}

@keyframes show {
    from { opacity: 0; }
    to { opacity: 1; }
}
@media (max-width: 1300px) {
    .IFCForeheadIllustration {
        width: 60%;
        padding-top: 8px;
        display: flex;
        justify-content: flex-end;
    }

    .IFCForeheadIllustrationBackground {
        position: absolute;
        right: -200px;
        top: 0;
        z-index: 0;
    }

    .IFCForeheadIllustrationGraphic {
        position: relative;
        z-index: 1;
        width: 496px;
        height: 474px;
    }
}

@media (max-width: 1300px) {
    .IFCFlexContainerIllustrationBigger {
        width: inherit;
    }

    .IFCFlexContainerIllustration {
        width: 689px;
        height: 402px;
    }
}

@media (max-width: 1200px) {
    .IFCWebAsDesktopVersionIllustration {
        width: 600px;
        height: auto;
    }

    .IFCFlexContainerIllustration {
        width: 639px;
        height: 372px;
        border-radius: 4px;
        box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0px 4px 20px -3px rgba(30, 44, 101, 0.05);
    }
}

@media (max-width: 1100px) {
    .IFCFlexContainerIllustration {
        width: 529px;
        height: 302px;
        border-radius: 4px;
        box-shadow: -10px 30px 60px -8px rgba(30, 44, 101, 0.15), 0px 4px 20px -3px rgba(30, 44, 101, 0.05);
    }
    .IFCForeheadIllustrationBackground {
        position: absolute;
        right: -300px;
        top: 0;
        z-index: 0;
    }
}

@media (max-width: 968px) {
    .IFCPowerVisoplan h2 {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        margin: 0 0 40px;
        text-align: left;
    }

    .IFCFlexContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        padding: 40px 8%;
    }


    .IFCFlexContainerIllustration {
        width: 326px;
        height: 188px;
        margin: 40px auto 24px;
    }

    .IFCFlexContainerData {
        width: 100%;
        margin: 0;
    }

    .IFCFlexContainerReverse {
        flex-direction: column-reverse;
    }

    .IFCFlexContainerData p {
        color: #5A667B;
    }

    .IFCForeheadIllustrationBackground {
        display: none;
    }

    .IFCForeheadIllustrationGraphic {
        display: none;
    }

    .IFCBACK {
        display: none;
    }

    .IFCForeheadIllustrationGraphicSmallScreen {
        display: block;
        height: 470px;
        width: 330px;
        z-index: 2;
        margin-top: 100px;
    }

    .IFCForeheadIllustrationBackgroundSmallScreen {
        display: block;
        position: absolute;
        width: 100vw;
        top: 0;
        z-index: 0;
    }

    .IFCForehead {
        flex-direction: column;
        height: 1273px;
    }

    .IFCForeheadData {
        width: 100%;
        padding: 0 6%;
    }

    .IFCForeheadIllustration {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .IFCForeheadData h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 31px;
        line-height: 39px;
        letter-spacing: -0.02em;
        margin: 0 0 16px;
    }

    .IFCForeheadData .subheading {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 40px;
    }

    .IFCForeheadForm input {
        height: 48px;
        width: 100%;
        box-sizing: border-box;
    }
    .IFCWebAsDesktopVersion {
        flex-direction: column;
        justify-content: normal;
        padding: 40px 0;
        height: auto;
    }

    .IFCWebAsDesktopVersionData h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 31px;
        text-align: center;
    }

    .IFCWebAsDesktopVersionData {
        margin: 0;
        padding: 0 32px 45px;
    }

    .IFCWebAsDesktopVersionData .subheading {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0;
        text-align: center;
    }

    .IFCWebAsDesktopVersionData ul li {
        margin: 0 auto 8px;
        width: fit-content;
        block-size: fit-content;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
    }

    .IFCWebAsDesktopVersionIllustration {
        width: 100%;
    }
    .IFCPowerVisoplan {
        padding: 60px 6%;
    }
}

@media (max-width: 400px) {
    .IFCForeheadData {
        width: 100%;
        padding: 0;
    }

    .IFCFlexContainer {
        padding: 40px 6%;
    }
}

@media (min-width: 1600px) {
    .IFCBACK {
        width: 60%;
    }
}
@media (min-width: 1800px) {
    .IFCBACK {
        width: 54%;
    }
}

@media (min-width: 1920px) {
    .IFCFlexContainer {
        padding: 80px 10%;
    }
    .IFCForehead{
        padding:80px 5% 80px 10%;
    }
    .IFCPowerVisoplan {
        padding: 120px 10%;
    }
    .AnimatedBlock {
        padding: 80px 10%;
    }
    .IFCWebAsDesktopVersionData {
        margin: auto 0 auto 10%;
    }
}