.PricingForehead {
    padding: 95px 5% 120px;
}

.PricingForeheadTitle {
    width: 60%;
    margin: 0 auto
}

.PricingForehead h1, .PricingForehead h2 {
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #252626;
}

.PricingForeheadTitle h4, .PricingForeheadTitle .subheading {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    margin-top: 36px;
}

.PricingForeheadDataContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 120px;
}

.PricingForeheadDataInfo {
    width: 41%;
}

.PricingForeheadDataInfo h2 {
    text-align: left;
}

.PricingForeheadDataInfo .subheading {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    width: 80%;
}

.PricingForeheadCards {
    width: 58%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 32px;
}

.PricingForeheadCard {
    height: 256px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 48px 0 56px;
    box-sizing: border-box;
    color: #FFFFFF;
}

.PricingForeheadCardSmall {
    width: 47.8%;
}

.PricingForeheadCard img {
    margin: 0 auto;
    display: block;
}

.PricingForeheadCard h6 {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    margin: 16px 0 8px;
}

.PricingForeheadCard p {
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.PricingForeheadCardSmall p {
    width: 72%;
}

.PricingForeheadCardBig {
    width: 100%;
}

.PricingForeheadListOfBenefits {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 16px auto 0;
}

.PricingForeheadListOfBenefits li {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.PricingPlans {
    height: 1441px;
    padding: 120px 0;
    background: #F6F8F9;
}

.PricingPlansTitleBlock {
    width: 76%;
    margin: 0 auto;
}

.PricingPlansTitleBlock h1, .PricingPlansTitleBlock h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 49px;
    line-height: 61px;
    text-align: center;
    letter-spacing: -0.02em;
    width: 96%;
}

.PricingPlansTitleBlock h4, .PricingPlansTitleBlock .subheading {
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    margin-top: 24px;
}

.PricingPlansCards {
    width: 1280px;
    margin: 64px auto 0;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.PricingPlanCard {
    width: 632px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 16px;
    height: 568px;
    position: relative;
}

.PricingPlanVisoplan {
    box-shadow: 0 8px 30px -10px rgba(30, 44, 101, 0.12), 0 2px 6px -1px rgba(30, 44, 101, 0.05);
}

.PricingPlanCompetitors {
    border: 1px dashed #9CA3B0;
    display: flex;
    justify-content: space-between;
    padding: 48px 71px;
    background: #F6F8F9;
}

.PricingPlanVisoplanTitleBlock {
    display: flex;
    justify-content: normal;
    width: 75%;
    margin: 60px auto 44px;
}

.PricingPlanVisoplanTitleBlock p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    padding: 0;
    margin: 0;
}

.PricingPlansThatIS {
    margin: 0 0 0 5px !important;
}

.PricingPlansThatIS img {
    margin-left: 5px;
}

.PricingPlanVisoplanCard {
    width: 460px;
    height: 120px;
    margin-left: 5px;
    border-radius: 4px;
    box-sizing: border-box;
}

.PricingPlanVisoplanCards {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 460px;
    margin: 0 auto;
    color: #FFFFFF;
    box-sizing: border-box;
}

.PricingPlanVisoplanCardTitle {
    margin: 16px 19px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
}

.PricingList {
    display: flex;
    list-style: none;
    margin: 4px 16px 0;
    padding: 0;
    flex-wrap: wrap;
}

.PricingList li {
    width: 48%;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.PricingList li:nth-child(odd) {
    width: 52%;
}


.PricingList li img {
    margin-right: 4px;
}

.PricingOperationList li {
    width: 100% !important;
    align-items: flex-start;
}

.PricingOperationList li p {
    line-height: 16px;
    margin: 0;
}

.PricingPlanVisoplanFooter {
    width: 480px;
    height: 64px;
    background: #4B6FFC;
    border-radius: 4px;
    position: absolute;
    left: 76px;
    bottom: -36px;
    padding: 18px 7px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.PricingPlanCompetitorsColumn {
    width: 227px;
    height: 456px;
}

.PricingPlanCompetitorsColumn p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto 32px;
    height: 48px;
    width: 100%;
    padding: 0;
}

.PricingPlanCompetitorsColumnBlock {
    width: 187px;
    height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #9CA3B0;
    margin: 0 auto 8px;
    border-radius: 4px;
}

.PricingPlanCompetitorsColumnBlock:last-child {
    margin: 0 auto;
}

.PricingPlanCompetitorsColumnBlock p {
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align:left;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    padding-left: 16px;
    text-transform: capitalize;
    color: #9CA3B0;
    height: auto;
}

.PricingPlanCompetitorsColumnBlock p.Construction {
    letter-spacing: 0.02em;
}

.PricingPlanCompetitorsTitle1 {
    width: 100% !important;
    white-space: pre;
}

.PricingPlanCompetitorsColumnBlock img {
    width: 40px;
    height: 40px;
    padding-right: 11px;
}

.PricingPlanCompetitorsFooter {
    background: #9CA3B0 !important;
}

.PricingContactForm {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 1164px;
    height: 294px;
    margin: 184px auto 0;
}

.PricingContactForm h4 {
    width: 43%;
    font-style: normal;
    font-weight: 600;
    font-size: 31.25px;
    line-height: 39px;
    letter-spacing: -0.01em;
    color: #252626;
}

.PricingContactFormFlexContainer {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    flex-wrap: wrap;
}

.PricingContactFormFlexContainer input {
    width: calc(50% - 4px);
    padding: 12px 16px;
    box-sizing: border-box;
    border: 1px solid #9CA3B0;
    border-radius: 4px;
    outline: none;
    height: 48px;
    background: inherit;
    font-family: 'Poppins', sans-serif;
    color: #9CA3B0;
}

.PricingContactFormFlexContainer button {
    width: fit-content;
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.PricingContactFormFlexContainer button:hover {
    background: inherit;
    color: #4B6FFC;
    outline: 1px solid #4B6FFC;
}

.PricingContactFormBigInput {
    width: calc(100% - 129px + 8px) !important;
}

.PricingVSBlock {
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #252626;
    box-shadow: 0 8px 30px -10px rgba(30, 44, 101, 0.12), 0 2px 6px -1px rgba(30, 44, 101, 0.05);
    border-radius: 4px;
    width: 90px;
    height: 69px;
    position: absolute;
    top: 42%;
    left: 46%;
    z-index: 10;
}

.PricingPlanVisoplanFooter p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    padding: 0;
    text-align: center;
    margin: 16px 0;
    display: contents;
}

.PricingPlanVisoplanFooter p > br {
    display: none;
}

.PricingPlanVisoplanFooter h4 {
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    color: #FFFFFF;
    padding: 0;
    margin: 0 3px;
    display: inline;
}

.CalendlyWrapper {
    padding: 120px 5%;
}

.CalendlyWrapper h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 31.25px;
    line-height: 39px;
    margin: 0 auto 16px;
    text-align: center;
    letter-spacing: -0.01em;
    width: 60%;
    color: #252626;
}

.CalendlyWrapper .subheading {
    margin: 0 auto;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    width: 60%;
    text-align: center;
    color: #252626;
}

.PricingContactForm .span {
    font-weight: 400;
    padding: 0;
    margin: 5px 0 15px;
    line-height: 24px;
}
@media (max-width: 1400px) {
    .PricingForeheadCardSmall {
        width: 44.8%;
    }
}

@media (max-width: 1300px) {
    .PricingPlansCards {
        height: auto;
        width: 100%;
        display: block;
    }

    .PricingPlans {
        height: auto;
    }

    .PricingContactFormBigInput {
        display: block;
        width: calc(100% - 121px) !important;
    }

    .PricingPlanCard {
        height: auto;
        padding: 32px 0 64px;
        margin: 0 auto;
    }

    .PricingVSBlock {
        position: relative;
        top: auto;
        left: auto;
        margin: 100px auto 100px;
    }

    .PricingPlanCompetitors {
        padding-left: 20px;
        padding-right: 20px;
    }

    .PricingContactForm {
        width: 964px;
        height: auto;
        margin: 104px auto 0;
    }

    .CalendlyWrapper {
        padding: 120px 5%;
        max-width: 100%;
        overflow: hidden;
    }

}

@media (max-width: 1200px) {
    .CalendlyWrapper {
        padding: 80px 8%;
    }

    .PricingForeheadDataInfo {
        width: 36%;
        box-sizing: border-box;
    }
    .PricingForehead h1, .PricingForehead h2 {
        font-weight: 600;
        font-size: 40px;
        line-height: 51px;
    }

    .Divider {
        display: none;
    }
}

@media (max-width: 968px) {
    .CalendlyWrapper h4 {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        margin: 0 auto 16px;
        text-align: center;
        width: 90%;
    }

    .CalendlyWrapper .subheading {
        margin: 0 auto;
        padding: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        width: 90%;
    }
    .PricingPlanVisoplanFooter p {
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        color: #FFFFFF;
        display: inline;
        margin: 0;
        display: inline-table;
        line-break: loose;
    }

    .PricingPlanVisoplanFooter p > br {
        display: block;
    }

    .PricingPlanVisoplanFooter h4 {
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #FFFFFF;
        margin: 0 2px;
    }

    .PricingForehead {
        padding: 56px 10% 80px;
    }

    .PricingForeheadTitle {
        width: 100%;
        margin: 0 auto
    }

    .PricingForehead h1, .PricingForehead h2 {
        font-weight: 600;
        font-size: 31.25px;
        line-height: 39px;
    }

    .PricingForeheadTitle h4, .PricingForeheadTitle .subheading {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;
    }

    .PricingForeheadDataContainer {
        display: block;
        margin-top: 54px;
    }
    .Divider {
        display: block;
    }

    .PricingForeheadDataInfo {
        width: 100%;
    }

    .PricingForeheadDataInfo h2 {
        text-align: center;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
    }

    .PricingForeheadDataInfo .subheading {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        width: 100%;
        margin-top: 8px;
    }

    .PricingForeheadCards {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 40px;
    }

    .PricingForeheadCard {
        padding: 32px 20px;
        height: auto;
    }

    .PricingForeheadCardSmall {
        width: 100%;
        height: auto;
    }

    .PricingForeheadCardBig {
        width: 100%;
        height: auto;
    }

    .PricingForeheadCard img {
        width: 40px;
        height: 40px;
    }

    .PricingForeheadCard h6 {
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
    }

    .PricingForeheadCard p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        width: 100%;
    }

    .PricingForeheadListOfBenefits {
        list-style: none;
        display: block;
        width: 100%;
        margin: 16px auto 0;
    }

    .PricingForeheadListOfBenefits li {
        margin: 16px auto 0;
        padding: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        display: block;
        justify-content: flex-start;
        width: 80%;
    }

    .PricingForeheadListOfBenefits img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
        width: 24px;
        height: 24px;
    }

    .PricingPlans {
        height: auto;
        padding: 80px 10%;
        background: #F6F8F9;
    }

    .PricingPlansTitleBlock {
        width: 100%;
        margin: 0 auto;
    }

    .PricingPlansTitleBlock h1, .PricingPlansTitleBlock h3 {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        width: 100%;
    }

    .PricingPlansTitleBlock h4, .PricingPlansTitleBlock .subheading {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-top: 8px;
    }

    .PricingPlansCards {
        width: 326px;
        height: auto;
        margin: 48px auto 0;
        display: block;
    }

    .PricingPlanCard {
        width: 326px;
        height: auto;
        padding: 32px 0 64px;
    }

    .PricingPlanVisoplanTitleBlock {
        display: block;
        width: 74%;
        margin: 0 auto 24px;
    }

    .PricingPlanVisoplanTitleBlock img {
        width: 173px;
        height: 24px;
    }

    .PricingPlanVisoplanTitleBlock p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 8px;
        padding: 0;
    }

    .PricingPlanVisoplanCard {
        width: 310px;
        height: auto;
        margin: 0 auto;
        border-radius: 4px;
        box-sizing: border-box;
    }

    .PricingPlanVisoplanCards {
        width: 326px;
    }

    .PricingList {
        display: block;
        margin: 4px 16px 17px;
        padding: 0;
        flex-wrap: wrap;
    }

    .PricingList li {
        width: 100%;
        margin-bottom: 7px;
    }

    .PricingList li:nth-child(odd) {
        width: 100%;
    }


    .PricingList li img {
        margin-right: 8px;
    }

    .PricingPlanVisoplanFooter {
        width: 266px;
        height: 74px;
        background: #4B6FFC;
        border-radius: 4px;
        position: absolute;
        left: 30px;
        bottom: -36px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .PricingPlanCompetitors {
        margin-top: 50px;
        padding-left: 8px;
        padding-right: 8px;
    }

    .PricingPlanCompetitorsColumn {
        width: 151px;
        height: 271px;
    }

    .PricingPlanCompetitorsColumn p {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        margin: 0 auto 24px;
        padding: 0;
        height: 63px;
    }

    .PricingPlanCompetitorsColumnBlock {
        width: 151px;
        height: 56px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .PricingPlanCompetitorsColumnBlock:last-child {
        margin: 0 auto;
    }

    .PricingPlanCompetitorsColumnBlock p {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        letter-spacing: 0.1em;
        margin: 0;
        height: auto;
        padding-left: 16px;
    }


    .PricingPlanCompetitorsColumnBlock img {
        width: 24px;
        height: 24px;
    }

    .PricingContactForm {
        display: block;
        width: 100%;
        height: auto;
        margin: 100px 0 0;
    }

    .PricingContactForm h4 {
        width: 100%;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
    }

    .PricingContactFormFlexContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .PricingContactFormFlexContainer input {
        width: 100%;
        padding: 12px 16px;
        box-sizing: border-box;
        border: 1px solid #9CA3B0;
        border-radius: 4px;
        outline: none;
        height: 48px;
        font-family: 'Poppins', sans-serif;
        color: #9CA3B0;
    }

    .PricingContactFormFlexContainer button {
        width: fit-content;
        height: 48px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .PricingContactFormBigInput {
        width: 100% !important;
    }

    .PricingContactForm h4 {
        margin-bottom: 16px;
    }

    .PricingContactForm p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }

    .PricingVSBlock {
        position: relative;
        top: auto;
        left: auto;
        margin: 58px auto 0;
    }

    .PricingPlanCompetitorsTitle1 {
        width: 100% !important;
        white-space: normal;
    }
}

@media (max-width: 500px) {
    .PricingPlans {
        height: auto;
        padding: 80px 6%;
        background: #F6F8F9;
    }
}

@media (min-width: 1920px) {
    .PricingForehead {
        padding: 95px 10% 120px;
    }
}
